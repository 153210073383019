import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  GET_TREE_CUTTING_APPLICATIONS,
  ADD_SITEINSCPECTOR,
  SEARCH_TREE_CUTTING_APPLICATION,
  UPDATE_TREE_CUTTING_APPLICATION,
  GET_UPCOMING_REPLANTATION_DETAILS,
  ASSIGN_REPLANTATION,
  GET_REPLANTATION_HISTORY,
} from './Types';

const GetUpcomingReplantationDetails = (updatedBy, page, limit) => async (dispatch) => {
  const url = `/api/upcoming-replantation?update_by=${updatedBy}&page=${page}&limit=${limit}`;

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_UPCOMING_REPLANTATION_DETAILS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const UpdateTreeCuttingApplication = (obj, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/v1/cutting-tree-application/update-application/${id}`, obj);
    dispatch({
      type: UPDATE_TREE_CUTTING_APPLICATION,
      payload: response,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


const SearchUpcomingReplantationDetails = (updatedBy,page, limit, search) => async (dispatch) => {
  let url = `/api/upcoming-replantation?update_by=${updatedBy}&page=${page}&limit=${limit}`;
  if (search) {
    url = `${url}&search=${search}`;
  }
  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_UPCOMING_REPLANTATION_DETAILS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const AssignReplantation = (params) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`/api/assign-replantation`, params);
    dispatch({
      type: ASSIGN_REPLANTATION,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetReplantationHistory = (applicationId) => async (dispatch) => {
  const url = `/api/replantation-history/${applicationId}`;

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_REPLANTATION_HISTORY,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};


export {
  GetUpcomingReplantationDetails,
  SearchUpcomingReplantationDetails,
  AssignReplantation,
  UpdateTreeCuttingApplication,
  GetReplantationHistory
};
