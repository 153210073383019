import {
    RESET_STATE,
    GET_UPCOMING_REPLANTATION_DETAILS,
    ASSIGN_REPLANTATION,
    GET_REPLANTATION_HISTORY,
  } from '../actions/Types';
  
  const INIT_STATE = {
    pageInfo: {},
    upcomingReplantationDetails:[],
    replantationHistory : [],
    siteinspector: false,
  };
  
  export default function UpcomingReplantationReduer(state = INIT_STATE, action) {
    const { type, payload } = action;
  
    switch (type) {
      case GET_UPCOMING_REPLANTATION_DETAILS:
        console.log("IN REDUCEER",payload);
        return {
          ...state,
          upcomingReplantationDetails: payload.data,
          pageInfo: payload,
        };
      
      case GET_REPLANTATION_HISTORY:
        return {
          ...state,
          replantationHistory: payload.data,
        };
  
      case ASSIGN_REPLANTATION:
        return {
          ...state,
          siteinspector: !state.siteinspector,
        };
  
      case RESET_STATE:
        return { ...INIT_STATE };
  
      default:
        return state;
    }
  }
  