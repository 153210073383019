import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { Chart as ChartJS, LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip } from "chart.js";
import { Card, MenuItem, TextField } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';

ChartJS.register(LineElement, CategoryScale, LinearScale, PointElement, Legend, Tooltip);

const RevenueChart = (props) => {
  const [filterType, setFilterType] = useState('monthly');
  const [chartYLabel, setChartYLabel] = useState([]);
  const [chartXLabel, setChartXLabel] = useState([]);
  const [revenueArray, setRevenueArray] = useState([]);
  const [depositArray, setDepositArray] = useState([]);
  const [feesArray, setFeesArray] = useState([]);

  useEffect(()=>{
    if(props?.data?.monthly_revenue){
      
      const months = Object.keys(props.data.monthly_revenue);
      const totalRevenue = Object.values(props.data.monthly_revenue)
      setChartXLabel(months)
      setRevenueArray(totalRevenue)
    }
  },[props])

  const handleFilterChange = (e) => {
    if(e.target.value==="yearly"){
      const months = Object.keys(props.data.yearly_revenue);
      const totalRevenue = Object.values(props.data.yearly_revenue)
      setChartXLabel(months)
      setRevenueArray(totalRevenue)
    }
    else {
      const months = Object.keys(props.data.monthly_revenue);
      const totalRevenue = Object.values(props.data.monthly_revenue)
      console.log("X LABEL",months)
      setChartXLabel(months)
      setRevenueArray(totalRevenue)
    }
    setFilterType(e.target.value)
  }

  console.log("X LABEL",chartXLabel);
  const data = {
    labels: chartXLabel, // X-axis labels
    datasets: [
      {
        label: "Total Revenue(Deposit + Processing Fees)",
        data: revenueArray, // Revenue data
        borderColor: "green",
        backgroundColor: "rgba(0, 128, 0, 0.3)",
        fill: true,
        tension: 0.4, // For smooth curves
      },
      // {
      //   label: "Deposit Amount",
      //   data: depositArray, // Deposit data
      //   borderColor: "blue",
      //   backgroundColor: "rgba(0, 0, 255, 0.3)",
      //   fill: true,
      //   tension: 0.4,
      // },
      // {
      //   label: "Processing Fees",
      //   data: feesArray, // Processing Fees data
      //   borderColor: "red",
      //   backgroundColor: "rgba(255, 0, 0, 0.3)",
      //   fill: true,
      //   tension: 0.4,
      // },
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: filterType === "monthly"?`Months (${(new Date().getFullYear())})` : "Year" ,
        },
      },
      y: {
        title: {
          display: true,
          text: "Amount (in INR)",
        },
      },
    },
  };

  const useStyles = makeStyles({
    common: {
      padding: '5px 5px 5px 5px',
      border: '2px solid #d1cfcf',
    },
    icon: {
      fill: '#214C50',
    },
  });

    const classes = useStyles();

  return (
    <div>
      <Card className={classes.common}>
      <div style={{width:'20%',display:'flex',justifySelf:'flex-end',marginRight:'2%'}}>
      <TextField
                    fullWidth
                      select
                      id="revenueBy"
                      name="revenueBy"
                      displayEmpty
                      value={filterType}
                      style={{ width: '100%', marginTop: 10 }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e)
                      }}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem key="monthly" value="monthly">
                        <em>Monthly</em>
                      </MenuItem>
                      <MenuItem key="yearly" value="yearly">
                        <em>Yearly</em>
                      </MenuItem>
                     
                    </TextField>
      </div>
      <Line data={data} options={options} />
      </Card>
    </div>
  );
};

export default RevenueChart;
