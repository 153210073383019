import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import ReactDOMServer from 'react-dom/server';
import { useDispatch, useSelector } from 'react-redux';
import { styled } from '@mui/material/styles';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { Box, Button, Divider, generateUtilityClasses, Grid, Menu, MenuItem, Tab, Typography } from '@mui/material';
import ArrowDropDownCircleOutlinedIcon from '@mui/icons-material/ArrowDropDownCircleOutlined';
import TabContext from '@mui/lab/TabContext';

import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { jsPDF as Jspdf } from 'jspdf';
import html2canvas from 'html2canvas';
import { GetTreeCuttingApplicationById, GetTreeReplantationDetailsByAlicationById } from '../../../actions/TreeCuttingPermissionAction';
import ProclamationApplicationDetails from './ProclamationApplicationDetails';

import ViewDetailsApplication from './ViewDetailsApplication';
import ViewMeetingList from './viewMeetingList';
import InspectionReport from './InspectionReport';
import ReplantationDetails from './ReplantationDetails';

//

const tabStyles = {
  // borderRadius: '4px',
  minWidth: '120px',
  // padding: '8px 16px',
  textTransform: 'none',
  fontWeight: 'bold',
  margin: '0 4px',
  // backgroundColor: '#e0e0e0',
  '&.Mui-selected': {
    color: '#214c50',
  },
  '&:hover': {
    backgroundColor: '#c5cae9',
  },
};

export default function ViewDetailsTab() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const applicationId = useParams();
  const inputRef = useRef(null);
  const [applicationDetailsData, setApplicationDetailsData] = useState(false);
  const [replanationDetails, setReplanationDetails] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    if(newValue==="5"){
      setShowLoader(true)
      // dispatch(GetTreeReplantationDetailsByAlicationById(applicationId.applicationId)).then((response) => {
      dispatch(GetTreeReplantationDetailsByAlicationById(applicationId.applicationId)).then((response) => {
        if (response.status === 200) {
          const data = response.data;
          if (data) {
            setShowLoader(false);
            setReplanationDetails(response.data);
          }
        }
      });
    }
    setValue(newValue);
    // localStorage.setItem('tabValue', newValue);
  };

  useEffect(() => {
    if (applicationId) {
      setShowLoader(true);

      dispatch(GetTreeCuttingApplicationById(applicationId.applicationId)).then((response) => {
        console.log('byidresponse', response);
        if (response.status === 200) {
          const data = response.data.data;
          if (data) {
            setShowLoader(false);
            setApplicationDetailsData(response.data.data);
          }
        }
      });
    }
  }, []);

  const getApplicationById = () => {
    setShowLoader(true);
    dispatch(GetTreeCuttingApplicationById(applicationId.applicationId)).then((response) => {
      console.log('byidresponse', response);
      if (response.status === 200) {
        const data = response.data.data;
        if (data) {
          setShowLoader(false);
          setApplicationDetailsData(response.data.data);
        }
      }
    });
  }

  // const exportReports = async () => {
  //   html2canvas(inputRef.current).then((canvas) => {
  //     const imgData = canvas.toDataURL('image/png');
  //     const pdf = new Jspdf();
  //     pdf.addImage(imgData, 'JPEG', 0, 0);
  //     pdf.save('download.pdf');
  //   });
  // };
  const exportReports = async () => {
    const inputElement = inputRef.current;

    if (!inputElement) {
      console.error('No input element found');
      return;
    }

    try {
      // Capture the HTML content as canvas
      const canvas = await html2canvas(inputElement);
      const imgData = canvas.toDataURL('image/png');

      // Create a new jsPDF instance
      const pdf = new Jspdf('p', 'mm', 'a4');

      // Calculate the PDF dimensions
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = pdf.internal.pageSize.getHeight();
      const imgWidth = canvas.width;
      const imgHeight = canvas.height;
      const ratio = imgWidth / imgHeight;

      let pdfImgWidth = pdfWidth;
      let pdfImgHeight = pdfWidth / ratio;

      // If the image height is larger than the PDF height, adjust accordingly
      if (pdfImgHeight > pdfHeight) {
        pdfImgHeight = pdfHeight;
        pdfImgWidth = pdfHeight * ratio;
      }

      // Add image to PDF
      pdf.addImage(imgData, 'PNG', 0, 0, pdfImgWidth, pdfImgHeight);

      // Save the PDF
      pdf.save('download.pdf');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };

  console.log("APPLICA applicationDetailsData", applicationDetailsData);

  return (
    <div>
      <Grid container md={12} sx={{ px: 4 }} ref={inputRef}>
        <Grid md={12}>
          <TabContext value={value}>
            <Grid item md={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
              <Box>
              {applicationDetailsData?.application_status?.application_status==="Rejected" ?
                <TabList sx={{ mt: '-18px' }} onChange={handleChange}>
                  <Tab label="Application Detail" value="1" sx={tabStyles} />
                  <Tab label="Inspection Report" value="2" sx={tabStyles} />
                  <Tab label="Meeting Report" value="3" sx={tabStyles} />
                </TabList>:
                <TabList sx={{ mt: '-18px' }} onChange={handleChange}>
                <Tab label="Application Detail" value="1" sx={tabStyles} />
                <Tab label="Inspection Report" value="2" sx={tabStyles} />
                <Tab label="Meeting Report" value="3" sx={tabStyles} />
                <Tab label="Proclamation Details" value="4" sx={tabStyles} />
                <Tab label="Re-Plantation Detail" value="5" sx={tabStyles} />
              
              </TabList>
    }
              </Box>
              <Box>
                {/* <Button
                  variant="contained"
                  size="small"
                  onClick={exportReports}
                  style={{ fontWeight: 500, fontSize: '15px', backgroundColor: '#E8762F', color: '#fff' }}>
                  Export Report
                </Button> */}
              </Box>
            </Grid>

            <Grid item md={12}>
              <TabPanel value="1" sx={{ m: 0, p: 0 }}>
                <ViewDetailsApplication applicationDetailsData={applicationDetailsData} showLoader={showLoader} fetchApplication = {getApplicationById} />
              </TabPanel>
              <TabPanel value="2" sx={{ m: 0, p: 0 }}>
                <InspectionReport applicationDetailsData={applicationDetailsData} showLoader={showLoader} />
              </TabPanel>
              <TabPanel value="3" sx={{ m: 0, p: 0 }}>
                <ViewMeetingList applicationDetailsData={applicationDetailsData} showLoader={showLoader} />
              </TabPanel>
              <TabPanel value="4" sx={{ m: 0, p: 0 }}>
                <ProclamationApplicationDetails applicationDetailsData={applicationDetailsData} showLoader={showLoader}/>
              </TabPanel>
              <TabPanel value="5" sx={{ m: 0, p: 0 }}>
              <ReplantationDetails applicationDetailsData={replanationDetails} showLoader={showLoader} />
              </TabPanel>
            </Grid>
          </TabContext>
        </Grid>
      </Grid>
      {/* </Page> */}
    </div>
  );
}
