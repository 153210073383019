import {
  ADD_MEETING,
  GET_MEETINGS,
  GET_TREE_CUTTING_APPLICATIONS,
  RESET_STATE,
  GET_ATTENDEES,
  UPDATE_MEETING,
  CANCEL_MEETING,
  GET_MEETING_BY_ID,
  EXCEL_MEETINGS_REPORT,
  ADD_APPLICATION_TO_MEETING,
  GET_FUTURE_MEETINGS,
  DETAILS_MEETING_BY_ID,
  ADD_MEETING_REMARK,
} from '../actions/Types';

const INIT_STATE = {
  applications: null,
  meeting: null,
  attendees: null,
  singleMeeting: null,
  addMeetingLog: false,
  pageInfo: {},
  excelData: null,
  meetingById: null,
  meetingRemark: null,
};

export default function MeetingReducer(state = INIT_STATE, action) {
  const { type, payload } = action;
  switch (type) {
    case ADD_MEETING:
      return {
        ...state,
        meeting: payload.data.data,
        pageInfo: payload.data,
        addUsersLog: !state.addUsersLog,
      };
    case GET_MEETINGS:
      return {
        ...state,
        meeting: payload.data.data,
        pageInfo: payload.data,
        singleMeeting: null,
      };
    case GET_MEETING_BY_ID:
      return {
        ...state,
        singleMeeting: payload.data,
        pageInfo: payload.data,
      };
    case UPDATE_MEETING:
      return {
        ...state,
        Meeting: payload.data.data,
        pageInfo: payload.data,
        singleMeeting: null,
      };
    case CANCEL_MEETING:
      return {
        ...state,
        Meeting: payload.data.data,
        pageInfo: payload.data,
        singleMeeting: null,
      };
    case GET_ATTENDEES:
      return {
        ...state,
        attendees: payload.data,
      };
    case GET_TREE_CUTTING_APPLICATIONS:
      return {
        ...state,
        applications: payload.data,
        pageInfo: payload.data,
      };
    case EXCEL_MEETINGS_REPORT:
      return {
        ...state,
        excelData: payload.data,
        pageInfo: payload.data,
        singleMeeting: null,
      };
    case ADD_APPLICATION_TO_MEETING:
      return {
        ...state,
        meeting: payload.data.data,
        pageInfo: payload.data,
        addUsersLog: !state.addUsersLog,
      };
    case GET_FUTURE_MEETINGS:
      return {
        ...state,
        meeting: payload.data.data,
        pageInfo: payload.data,
      };
    case DETAILS_MEETING_BY_ID:
      return {
        ...state,
        meetingById: payload.data,
        pageInfo: payload.data,
      };

    case ADD_MEETING_REMARK:
      return {
        ...state,
        meetingRemark: payload.data,
        pageInfo: payload.data,
      };
    case RESET_STATE:
      return { ...INIT_STATE };

    default:
      return state;
  }
}
