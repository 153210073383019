import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  Tooltip,
  CircularProgress,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { downloadExcel } from 'react-export-table-to-excel';

import TextWithTooltip from '../../components/Tooltip';

import MeetingListModal from '../../components/DialogBox/meeting/MeetingListModal';
import {
  GetTreeCuttingApplication,
  GetExcelTreeCuttingApplication,
  SearchTreeCuttingApplication,
  GetSiteInscepectorRellList,
} from '../../actions/TreeCuttingPermissionAction';

import Page from '../../components/Page';

import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import StatusChip from '../../components/StatusChip';
import SideInscpectorList from '../../components/DialogBox/SiteInscpectorList';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';
import ApplicationStatusChip from '../../components/ApplicationStatusChip';
import UpcomingReplanationMenu from '../../components/UpcomingReplanationMenu';
import UpcomingReplantationToolbar from '../../sections/@dashboard/tree-cutting/UpcomingReplantationToolbar';
import { GetUpcomingReplantationDetails, SearchUpcomingReplantationDetails } from '../../actions/UpcomingReplantationAction';
import UpcomingReplantationHistory from '../../components/DialogBox/TreeCutting/UpcomingReplantationHistory';

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'application no', label: 'Application No', alignRight: false },
  { id: 'name', label: 'Applicant', alignRight: false },
  { id: 'contact', label: 'Contact No', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  { id: 'cutTree', label: 'Cut Tree', alignRight: false },
  { id: 'totalTreestoBePlanted', label: 'Total trees to be planted', alignRight: false },
  { id: 'totalTreesPlanted', label: 'Total trees planted', alignRight: false },
  { id: 'totalTreesDied', label: 'Total died trees', alignRight: false },
  { id: 'totalExpectedTrees', label: 'Total Expected Trees', alignRight: false },
  { id: 'totalUpdatedTrees', label: 'Total Updated Trees', alignRight: false },
  { id: 'dueDate', label: 'Due Date', alignRight: false },
  { id: 'assignedTo', label: 'Assigned to', alignRight: false },
  { id: 'action', label: 'Action', alignRight: false },
];

export default function UpcomingReplantation(props) {
  const dispatch = useDispatch();
  const maxLength = 16;
  const [searchValue, setSearchValue] = useState(null);
  console.log('searchValue1998', searchValue);

  const [statusId, setStatusId] = useState('');
  const [showList, setShowList] = useState(false);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // const [lastPage, setLastPage] = useState(2);
  // const scrollContainerRef = useRef(null);
  // const loader = useRef(null);
  const [meetingParam, setMeetingParam] = useState({});
  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [meetingId, setMeetingId] = useState('');
    const [isReassigned, setIsReassigned] = useState(false);
    const [showHistoryModal, setShowHistoryModal] = useState(false);
    const [historyApplicationId, setHistoryApplicationId] = useState(false);
  // To be removed
  const [meetingList, setMeetingList] = useState([
    {
      id: 1,
      meeting_title: 'Meeting related to cutting tree near power grid ',
      meeting_status: 'Scheduled',
      date_of_meeting: '21-08-2024',
      meeting_time: '',
    },
    {
      id: 2,
      meeting_title: 'Meeting related to historical tree cutting',
      meeting_status: 'Scheduled',
      date_of_meeting: '25-08-2024',
      meeting_time: '',
    },
    {
      id: 3,
      meeting_title: 'Meeting related to partially uprooted tree',
      meeting_status: 'Pending',
      date_of_meeting: '',
      meeting_time: '',
    },
    {
      id: 4,
      meeting_title: 'Meeting to cut trees for colonial establishments',
      meeting_status: 'Pending',
      date_of_meeting: '20-08-2024',
      meeting_time: '',
    },
    {
      id: 5,
      meeting_title: 'Meeting to cut trees for requesters personal comfort',
      meeting_status: 'Scheduled',
      date_of_meeting: '7-08-2024',
      meeting_time: '',
    },
  ]);

  const [siteInspectorList, setSiteInspectorList] = useState([]);
  const [updatedBy, setUpdatedBy] = useState("site_inspector");
  const [replantationUpdateId , setReplantationUpdateId] = useState("");
  

  console.log('useLocation12', useLocation);

  useEffect(() => {
    dispatch(GetSiteInscepectorRellList()).then((response) => {
      console.log('response45365', response);
      setSiteInspectorList(response?.data?.data?.data);
    });
  }, []);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleStatusChange = (e) => {
    setStatusId(e.target.value);
  };

  const useStyles = makeStyles({
    success: {
      backgroundColor: '#DDFAD1',
      color: '#507C59',
      border: '1px solid #507C59',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
    },
    pending: {
      backgroundColor: '#efcbbd',
      color: '#CE5623',
      border: '1px solid #CE5623',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
      pointerEvents: 'none',
    },
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const sampleData = [{}];

  const {
    upcomingReplantationDetails,
    excelTreeCuttingApplications,
    isReportDownloadable,
    pageInfo,
    searchTreeCuttingApplications,
    siteinspector
  } = useSelector((state) => ({
    upcomingReplantationDetails: state.upcomingReplantation.upcomingReplantationDetails,
    excelTreeCuttingApplications: state.treeCutting.excelTreeCuttingApplications,
    isReportDownloadable: state.treeCutting.isReportDownloadable,
    pageInfo: state.upcomingReplantation.pageInfo,
    searchTreeCuttingApplications: state.treeCutting.searchTreeCuttingApplications,
    siteinspector: state.upcomingReplantation.siteinspector,
  }));

  useEffect(() => {
    setLoader(true);
    dispatch(GetUpcomingReplantationDetails(updatedBy,1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  }, [dispatch,siteinspector]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
      setCurrentPage(pageInfo?.current);
    }
  }, [pageInfo]);

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    // setFilteredApplication(treeCuttingAllApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, upcomingReplantationDetails]);

  const thirdRun = useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    // setFilteredApplication(searchTreeCuttingApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, searchTreeCuttingApplications]);

  useEffect(() => {
    if (searchValue ) {
      // const searchParam = {
      //   page,
      //   limit: rowsPerPage,
      //   search: searchValue,
      // };

      dispatch(SearchUpcomingReplantationDetails(updatedBy,1,rowsPerPage,searchValue)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      });
    } else {
      dispatch(GetUpcomingReplantationDetails(updatedBy, 1, rowsPerPage));
    }
  }, [searchValue]);



  /* #endRegion */

  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);

    setLoader(true);
    // setShowList(false);
    if (searchValue) {
      dispatch(SearchUpcomingReplantationDetails(updatedBy,newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetUpcomingReplantationDetails(updatedBy, newPage, rowsPerPage));
    }
  };



  const handleCloseModal = () => {
    setOpen(!open);
    dispatch(GetUpcomingReplantationDetails(updatedBy, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      console.log('APPLICATIONID', application?.applicationId);
      console.log('MeetingId', meetingId);
    }
  };
  const [openSiteIncepectorList, setOpenSiteIncepectorList] = useState(false);

  const handleAddSiteInscpector = (data, value) => {
    if (value) {
      setApplication({ applicationId: value.id, applicationName: value.application_number });
      setReplantationUpdateId(value.upcoming_replantation_updates.id)
      if(value?.upcoming_replantation_updates?.assigned_to) {
        setIsReassigned(true)
      }
      else {
        setIsReassigned(false)
      }
    }
    setOpenSiteIncepectorList(true);
  };

  const handleClosesiteList = () => {
    setOpenSiteIncepectorList(false);
    setLoader(true);
    dispatch(GetUpcomingReplantationDetails(updatedBy, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const changeUpdatedBy = (e) => {
    setUpdatedBy(e.target.value)
    setLoader(true);
    dispatch(GetUpcomingReplantationDetails(e.target.value, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  }

  const handleClearSearch = () => {
    setSearchValue("");
  }

  // useEffect(() => {
  //   setApplicantStatus(value.applicant_status.application_status);
  // }, [value.applicant_status.application_status]);

  const handleShowHistoryModal = (applicationId) => {
    setShowHistoryModal(!showHistoryModal)
    if(applicationId) {
      setHistoryApplicationId(applicationId)
    }
  }

  return (
    <Page title="Tree Cutting Application">
      <Container>
        {/* modules  */}
        {open ? (
          <MeetingListModal
            isOpen={open}
            handleClick={handleClick}
            handleClose={handleCloseModal}
            meetingList={meetingList}
            application={application}
          />
        ) : null}
        { showHistoryModal ?
          <UpcomingReplantationHistory
          isOpen={showHistoryModal}
          applicationId={historyApplicationId}
          handleClose = {handleShowHistoryModal}
           /> :null
        }
        {openSiteIncepectorList ? (
          <SideInscpectorList
            replantationUpdateId = {replantationUpdateId}
            isReplantationSiteInspector
            isOpen={openSiteIncepectorList}
            isReassigned = {isReassigned}
            handleClick={handleClick}
            handleClose={handleClosesiteList}
            siteInspectorList={siteInspectorList}
            application={application}
          />
        ) : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Upcoming Replantation
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all upcoming replantation
            </Typography>
          </Typography>
        </Stack>

        <Card>
        <UpcomingReplantationToolbar
            onFilterName={filterByName}
            handleClearSearch = {handleClearSearch}
            updatedBy={updatedBy}
            changeUpdatedBy={changeUpdatedBy}
          
          />
          <TableContainer sx={{ minWidth: 700, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {upcomingReplantationDetails?.length > 0 ? (
                    upcomingReplantationDetails?.map((value, index) => {
                      return (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{pageInfo.per_page * (pageInfo.current_page - 1) + index + 1}</b>
                          </TableCell>
                          <TableCell>{value.application_number}</TableCell>
                          <TableCell align="left">
                            <TextWithTooltip text={value.name} maxLength={maxLength} />
                          </TableCell>
                          <TableCell align="left">{value.mobile}</TableCell>
                          <TableCell align="center">{value?.ward?.name}</TableCell>
      
                          <TableCell align="left">{value.total_trees_permitted}</TableCell>

                          <TableCell align="left">{value?.total_trees_to_be_planted}</TableCell>
                          <TableCell align="left">{value?.total_trees_replanted}</TableCell>
                          <TableCell align="left">{value?.died_replanted_trees}</TableCell>
                          <TableCell align="left">{value?.upcoming_replantation_updates?.total_expected_trees}</TableCell>
                          <TableCell align="left">{value?.upcoming_replantation_updates?.total_updated_trees}</TableCell>
                          <TableCell align="left">{value?.upcoming_replantation_updates_due_date}</TableCell>
                          <TableCell align="left">{value?.upcoming_replantation_updates?.assigned_to ? value?.upcoming_replantation_updates?.assigned_to?.full_name:"-"}</TableCell>

                          <TableCell align="right">
                            <UpcomingReplanationMenu
                              handleAddSiteInscpector={() => handleAddSiteInscpector(meetingList, value)}
                              handleHistoryModal = {()=>handleShowHistoryModal(value.id)}
                              data={value}
                              disabled = {value?.upcoming_replantation_updates?.assigned_to}
                              showReassign = {value?.upcoming_replantation_updates?.assigned_to && value?.upcoming_replantation_updates?.total_expected_trees !==value?.upcoming_replantation_updates?.total_updated_trees}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page }
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
