import React, { useState, useEffect } from 'react';
import * as Yup from 'yup';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import {
  Link,
  Chip,
  Button,
  Grid,
  TextField,
  Typography,
  Stack,
  Card,
  Autocomplete,
  IconButton,
  CircularProgress,
  CardMedia,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useFormik } from 'formik';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { RedeemTwoTone } from '@material-ui/icons';
import TextWithTooltip from '../../components/Tooltip';
import StatusChip from '../../components/StatusChip';

import {
  GetAttendeesList,
  GetTreeCuttingApplicationsList,
  AddMeeting,
  GetMeetingById,
  UpdateMeeting,
  resetMeeting,
} from '../../actions/MeetingActions';
import pdfFile from '../../Assets/pdf.png'

export default function CreateMeeting(props) {
  const dispatch = useDispatch();

  const navigate = useNavigate();
  const location = useLocation();
  const { meetingId } = useParams();

  const { attendees, applications, singleMeeting } = useSelector((state) => ({
    attendees: state.MeetingReducer.attendees,
    applications: state.MeetingReducer.applications,
    singleMeeting: state.MeetingReducer.singleMeeting,
  }));

  console.log('singleMeeting', singleMeeting);
  // const [meetingId, setmeetingId] = useState();
  // console.log('meetingId', meetingId);
  const [attendeesData, setAttendeesData] = useState([]);
  const [applicationData, SetApplicationData] = useState([]);
  const [agendaCount, setAgendaCount] = useState(singleMeeting?.agendas?.length - 2);
  const [selectedOptions, setSelectedOptions] = useState(
    singleMeeting ? singleMeeting?.attendees?.map((info) => info) : []
  );
  const [selectedAttendeeOptions, setSelectedAttendeeOptions] = useState([]);
  const [selectedOptions2, setSelectedOptions2] = useState(
    singleMeeting ? singleMeeting?.treeCuttingApplications?.map((info) => info) : []
  );
  console.log('selectedOptions2', selectedOptions2);
  const [selectedApplicationeOptions, setselectedApplicationeOptions] = useState([]);
  const [images, setImages] = useState([]);
  const [editimages, setEditImages] = useState([]);
  const [imageError, setImageError] = useState(null);
  const [agendaValue, setAgendaValue] = useState([]);
  const [loader, setLoader] = useState(false);
  const[submitLoader, setSubmitLoader] = useState(false);
  const [attendeName, setAttendeName] = useState();

  const currentDate = new Date().toISOString().split('T')[0];
  const currentTime = new Date().toTimeString().slice(0, 5);

  console.log("Attendees",attendees)

  // console.log('singleData', singleData);

  useEffect(() => {
    setLoader(true);
    dispatch(GetAttendeesList()).then((response) => {
      console.log('res', response?.status);
      if (response?.status === 200) {
        setAttendeesData(attendees);
        setLoader(false);
      }
    });
    dispatch(GetTreeCuttingApplicationsList()).then((response) => {
      if (response?.status === 200) {
        SetApplicationData(response?.data.data);
        setLoader(false);
      }
    });
    // setmeetingId(location.state);
  }, []);

  useEffect(() => {
    if (meetingId) {
      dispatch(GetMeetingById(meetingId)).then((response) => {
        if (response.status === 200) {
          const data = response.data.data;
          setSelectedOptions(data?.attendees || []);
          setSelectedOptions2(data?.cutting_tree_applications || []);
          setImages(data.cutting_tree_meeting_document || []);
          formik.setValues({
            meeting_title: data?.meeting_title || '',
            meetingDate: moment(data?.date_of_meeting).format('YYYY-MM-DD') || '',
            meetingTime: moment(data?.meeting_time, 'h:mm A').format('HH:mm') || '',
            attendees: data?.attendees?.map((info) => info.id) || [],
            treeCuttingApplications: data?.cutting_tree_applications?.map((info) => info.id) || [],
            additional_notes: data?.remarks || '',
            agenda1: data?.agendas && data?.agendas.length > 0 ? data?.agendas[0]?.agenda || '' : '',
            agenda2: data?.agendas && data?.agendas.length > 1 ? data?.agendas[1]?.agenda || '' : '',
            agenda3: data?.agendas && data?.agendas.length > 2 ? data?.agendas[2]?.agenda || '' : '',
            files: data?.cutting_tree_meeting_document || [],
          });
          setLoader(false);
        } else {
          console.error('Failed to fetch meeting data');
        }
      });
    }else{
      setSelectedOptions([]);
      setSelectedOptions2([]);
      setImages([]);
    }
  }, [dispatch, meetingId]);

  const isCreateMode = location.pathname === '/dashboard/create-meeting';

  useEffect(() => {
    if (isCreateMode) {
      formik.resetForm();
    }
  }, [isCreateMode]);

  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
  const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'pdf'];

  const MeetingSchema = Yup.object().shape({
    meeting_title: Yup.string()
    .min(5, 'Minimum 5 character required')
      .max(30, 'Maximum 30 Character required')
      .required('Meeting Title is required'),
    meetingDate: Yup.date()
      .required('Meeting date is required')
      .min(
        new Date(new Date().setHours(0, 0, 0, 0)), // Sets the time to midnight of today, allowing the entire current day
        'Meeting date cannot be in the past'
      ),
    meetingTime: Yup.string().required('Meeting time is required')
    .test('minTime', 'Time cannot be in the past for today',  (value, context) => {
      const date = context.parent.meetingDate; // Accessing the date via context
      const currentDate = getCurrentDate();
      const currentTime = getCurrentTime();
      if (date && new Date(date).toLocaleDateString('en-CA') === currentDate && value < currentTime) {
        return false;
      }
      return true;
    }),
    agenda1: Yup.string().min(1, 'files required').required('agenda are required'),
    attendees: Yup.array().min(1, 'Attendees are required').required('Attendees are required'),
    // files: Yup.array().min(1, 'files required'),
    treeCuttingApplications: Yup.array()
      .min(1, 'Tree cutting applications are required')
      .required('Tree cutting applications are required'),
    // additional_notes: Yup.string().required('Meeting note is required'),
  });

  const formik = useFormik({
    initialValues: {
      // meeting_title: singleMeeting?.meeting_title?singleMeeting?.meeting_title:"",
      // meetingDate: singleMeeting?.date_of_meeting ? singleMeeting?.date_of_meeting : '',
      // meetingTime: singleMeeting?.meeting_time ? singleMeeting?.meeting_time : '',
      // attendees: selectedOptions,
      // treeCuttingApplications: selectedOptions2,
      // additional_notes: singleMeeting?.remarks ? singleMeeting?.remarks : '',
      // agenda1: singleMeeting?.agendas ? singleMeeting?.agendas[0].agenda : '',
      // files: images && images,
      // agenda2: singleMeeting?.agendas ? singleMeeting?.agendas[1]?.agenda : '',
      // agenda3: singleMeeting?.agendas ? singleMeeting?.agendas[2]?.agenda : '',

      meeting_title: '',
      meetingDate: '',
      meetingTime: '',
      attendees: [],
      treeCuttingApplications: [],
      additional_notes: '',
      agenda1: '',
      files: [],
      agenda2: '',
      agenda3: '',
    },
    validationSchema: MeetingSchema,
    onSubmit: (values) => {
      console.log('submit', values);
      setSubmitLoader(true);
      const formData = new FormData();
      formData.append('meeting_title', values.meeting_title);
      formData.append('meeting_date', values.meetingDate);
      formData.append('meeting_time', moment(values.meetingTime, 'HH:mm').format('h:mm A'));
      if (values?.attendees && values.attendees.length > 0) {
        values.attendees.forEach((attendeeId, index) => {
          formData.append(`meeting_attendees[${index}]`, attendeeId);
        });
      }
      if (values.treeCuttingApplications && values.treeCuttingApplications.length > 0) {
        values.treeCuttingApplications.forEach((application, index) => {
          formData.append(`cutting_tree_application[${index}][id]`, application);
        });
      }

      if (values.agenda1) {
        formData.append('meeting_agenda[0]', values.agenda1);
      }

      if (values.agenda2) {
        formData.append('meeting_agenda[1]', values.agenda2);
      }
      if (values.agenda3) {
        formData.append('meeting_agenda[2]', values.agenda3);
      }

      if (values.files && values.files.length > 0) {
        values.files.forEach((file, index) => {
          formData.append(`upload_files[${index}]`, file);
        });
      }
      formData.append('meeting_note', values.additional_notes);

      // for update
      const formData2 = new FormData();
      formData2.append('meeting_title', values.meeting_title);
      formData2.append('meeting_date', values.meetingDate);
      formData2.append('meeting_time', moment(values.meetingTime, 'HH:mm').format('h:mm A'));
      formData2.append('meeting_note', values.additional_notes);

      if (selectedOptions && selectedOptions.length > 0) {
        selectedOptions.forEach((attendeeId, index) => {
          console.log('kajalattendeeId', attendeeId);
          formData2.append(`meeting_attendees[${index}]`, attendeeId.id);
        });
      }
      if (selectedOptions2 && selectedOptions2.length > 0) {
        selectedOptions2.forEach((application, index) => {
          formData2.append(`cutting_tree_application[${index}][id]`, application.id);
        });
      }

      if (values.agenda1) {
        formData2.append('meeting_agenda[0]', values.agenda1);
      }

      if (values.agenda2) {
        formData2.append('meeting_agenda[1]', values.agenda2);
      }
      if (values.agenda2) {
        formData2.append('meeting_agenda[3]', values.agenda3);
      }
      if (agendaValue) {
        agendaValue.forEach((agenda, index) => {
          formData2.append(`meeting_agenda[${index + 3}]`, agenda);
        });
      }
      if (values.files && values.files.length > 0) {
        values.files.forEach((file, index) => {
          if (file?.name) {
            formData2.append(`upload_files[${index}]`, file);
          }
        });
      }
      // if (images && images.length > 0) {
      //   images.forEach((file, index) => {
      //     console.log('file0989', file);
      //     formData2.append(`upload_files[${index}]`, file.url);
      //   });
      // }

      if (meetingId) {
        dispatch(UpdateMeeting(formData2, meetingId)).then((response) => {
          if (response.status === 201) {
            setSubmitLoader(false);

            navigate('/dashboard/view-meetings');
          }
        });
      } else {
        dispatch(AddMeeting(formData)).then((response) => {
          if (response.status === 201) {
            setSubmitLoader(false);

            navigate('/dashboard/view-meetings');
          }
          else {
            setSubmitLoader(false);
          }
        }).catch((e)=>setSubmitLoader(false));
      }
    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  console.log("FORMIK.values",formik.values);
  const handleSingleRemove = (index) => {
    const updatedImages = [...images];
    updatedImages.splice(index, 1);
    setImages(updatedImages);
  };
  const handleFileChange = (event) => {
    const updatedImages = event.target.files;
    const maxFileSize = 14 * 1024 * 1024;
    const isValidFiles = allowedFileTypes.includes(updatedImages[0].type);
    const isValidSizes = updatedImages[0].size <= maxFileSize;

    if (!isValidFiles) {
      setImageError('File format not supported');
      formik.setFieldError('files', 'File format not supported');
    }
    if (!isValidSizes) {
      formik.setFieldError('files', 'File size exceeds 14 MB');
      return;
    }
    if (isValidFiles && isValidSizes) {
      setImageError();
      const reader = new FileReader();
      reader.onloadend = () => {
        const changedUpdatedImages = {
          file : event.target.files,
          type : updatedImages[0].type ==="application/pdf" ? 'pdf' : 'image',
          url : reader.result
        }
        formik.setFieldError('files', '');
      setImages((prevImages) => [...prevImages, changedUpdatedImages]);
      formik.setFieldValue('files', [...formik.values.files, ...updatedImages]);
      };
      reader.readAsDataURL(event.target.files[0]);
      
    }
  };
  const handleFileEditChange = (event) => {
   
    if(event.target.files){
      setImages([])
    }
    const updatedImages = event.target.files;
    const maxFileSize = 14 * 1024 * 1024;
    const isValidFiles = allowedFileTypes.includes(updatedImages[0].type);
    const isValidSizes = updatedImages[0].size <= maxFileSize;

    if (!isValidFiles) {
      setImageError('File format not supported');
      formik.setFieldError('files', 'File format not supported');
    }
    if (!isValidSizes) {
      formik.setFieldError('files', 'File size exceeds 14 MB');
      return;
    }
    if (isValidFiles && isValidSizes) {
      setImageError('');

      const reader = new FileReader();
      reader.onloadend = () => {
        const changedUpdatedImages = {
          file : event.target.files,
          type : updatedImages[0].type ==="application/pdf" ? 'pdf' : 'image',
          url : reader.result
        }
        formik.setFieldError('files', '');
        setEditImages((prevImages) => [...prevImages, changedUpdatedImages]);
      formik.setFieldValue('files', [...formik.values.files, ...updatedImages]);
      };
      reader.readAsDataURL(event.target.files[0]);

    }
  };


  // const handleCancel = () => {
  //   // Reset the file input and Formik state
  //   formik.setFieldValue('files', []);
  //   formik.setFieldError('files', '');
  //   setImageError('');

  //   // Force the file input to reset (if necessary)
  //   document.getElementById('fileInput').value = '';
  // };

  const renderOption = (props, option, { selected }) => {
    const Have100PlusTrees = option.have_100_plus_trees;
    const HaveHeritageTrees = option.have_heritage_trees;
    if (Have100PlusTrees === 1 && HaveHeritageTrees === 1) {
      return (
        <div {...props}>
          {option.application_number} <span style={{ color: 'red' }}> 100 Plus, Heritage</span>
        </div>
      );
    }
    if (Have100PlusTrees === 1) {
      return (
        <div {...props}>
          {option.application_number} <span style={{ color: 'red' }}> 100 Plus</span>
        </div>
      );
    }
    if (HaveHeritageTrees === 1) {
      return (
        <div {...props}>
          {option.application_number} <span style={{ color: 'red' }}> Heritage</span>
        </div>
      );
    }
    return <div {...props}>{option.application_number}</div>;
  };

  const getCurrentTime = () => {
    const now = new Date();
    let hours = now.getHours();
    let minutes = now.getMinutes();
    // Ensure 2 digits for hours and minutes
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${hours}:${minutes}`;
  };

  const getCurrentDate = () => {
    return new Date().toISOString().split('T')[0];
  };

  const handleTimeChange = (e) => {
    const selectedTime = e.target.value;
    if(formik.values.meetingDate === currentDate) {
      if (selectedTime < getCurrentTime()) {
        alert("sadsada")
        formik.setFieldValue("meetingTime",selectedTime);
        formik.setFieldError("meetingTime","Time cannot be in the past");
      } else {
        formik.setFieldError("meetingTime","");
        formik.setFieldValue("meetingTime",selectedTime);
      }
    }
  }

  const handleAttendeChange = (e) => {
    setAttendeName(e.target.value)
  }

  console.log("FORMIK",formik);

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {meetingId ? 'Update Application For Meeting' : 'New Application For Meeting'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      {submitLoader ? (
        <Card sx={{padding:"150px"}}>
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
          </Card>):(
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        {loader ? (
          <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </Grid>
        ) : (
          <form onSubmit={handleSubmit}>
            <Grid container rowSpacing={2} columnSpacing={2}>
              {/* meeting_title */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="meeting_title"
                  name="meeting_title"
                  label="Meeting Title*"
                  variant="outlined"
                  value={values.meeting_title}
                  onChange={formik.handleChange}
                  error={touched.meeting_title && Boolean(errors.meeting_title)}
                  helperText={touched.meeting_title && errors.meeting_title}
                />
              </Grid>
              {/* agenda1 */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="agenda1"
                  name="agenda1"
                  label="Meeting Agenda 1*"
                  variant="outlined"
                  value={values.agenda1}
                  onChange={formik.handleChange}
                  error={touched.agenda1 && Boolean(errors.agenda1)}
                  helperText={touched.agenda1 && errors.agenda1}
                />
              </Grid>
              {/* meetingDate */}
              <Grid item xs={3}>
                <TextField
                  id="meetingDate"
                  name="meetingDate*"
                  type="date"
                  label="Date*"
                  fullWidth
                  error={Boolean(touched.meetingDate && errors.meetingDate)}
                  helperText={touched.meetingDate && errors.meetingDate}
                  {...getFieldProps('meetingDate')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{
                    min: new Date().toISOString().split('T')[0],
                  }}
                />
              </Grid>
              {/* meetingTime */}
              <Grid item xs={3}>
                <TextField
                  id="meetingTime"
                  name="meetingTime"
                  label="Time*"
                  type="time"
                  fullWidth
                  inputProps={{
                    min: "08:00", // Set the minimum time
                    max: "20:00", // Set the maximum time
                    step: 60, // Optional: Define the step in seconds (e.g., 60s = 1 minute)
                  }}
                  value={values.meetingTime || ''}
                  error={Boolean(touched.meetingTime && errors.meetingTime)}
                  helperText={touched.meetingTime && errors.meetingTime}
                  onChange={(e)=> {
                    formik.handleChange(e)
                    // handleTimeChange(e);
                    
                  }}
                  // {...getFieldProps('meetingTime')}
                  InputLabelProps={{
                    shrink: true,
                  }}
                />
              </Grid>
              {/* agenda2 */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="agenda2"
                  name="agenda2"
                  label="Meeting Agenda 2 (Optional)"
                  variant="outlined"
                  value={values.agenda2}
                  onChange={formik.handleChange}
                  error={touched.agenda2 && Boolean(errors.agenda2)}
                  helperText={touched.agenda2 && errors.agenda2}
                />
              </Grid>
              <Grid item xs={6} />
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  id="agenda3"
                  name="agenda3"
                  label="Meeting Agenda 3 (Optional)"
                  variant="outlined"
                  value={values.agenda3}
                  onChange={formik.handleChange}
                  error={touched.agenda3 && Boolean(errors.agenda3)}
                  helperText={touched.agenda3 && errors.agenda3}
                />
              </Grid>

              {/* attendees */}
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  options={attendees || []}
                  getOptionLabel={(option) => option.full_name}
                  value={selectedOptions}
                  onChange={(event, newValue) => {
                    setSelectedOptions(newValue);
                    const selectedIds = newValue.map((option) => option.id);
                    setSelectedAttendeeOptions(selectedIds);
                    formik.setFieldValue('attendees', selectedIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="attendees"
                      variant="outlined"
                      label="Add Attendees*"
                      placeholder="Add Attendees"
                      error={touched.attendees && Boolean(errors.attendees)}
                      helperText={touched.attendees && errors.attendees}
                      // onChange={(e)=> handleAttendeChange(e)}
                    />
                  )}
                  filterSelectedOptions

                  filterOptions={(options, { inputValue }) => {
                    const filteredOptions = options.filter((option) => 
                      option.full_name.toLowerCase().includes(inputValue.toLowerCase())
                    );
                
                    // Exclude already selected options
                    return filteredOptions.filter((option) => 
                      !selectedOptions.some((selected) => selected.id === option.id)
                    );
                  }}

                />
              </Grid>

              {meetingId ? (
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', gap: '30px', flexWrap: 'wrap' }}>
                  <div>
                    <TextField
                      fullWidth
                      id="files-input"
                      name="files"
                      type="file"
                      multiple
                      InputProps={{ multiple: true }}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileEditChange(e)}
                      error={formik.touched.files && Boolean(formik.errors.files)}
                      helperText={formik.touched.files && formik.errors.files}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      aria-describedby="file-upload-label"
                    />

                    <Button
                      onClick={() => {
                        // setImages([]);

                        document.getElementById('files-input').click();
                      }}
                      disabled={editimages?.length >= 2}
                      sx={{
                        fontWeight: 500,
                        width: 'max-content',
                        height: '50px',
                        padding: '10px',
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        },
                      }}>
                      Re-upload files
                    </Button>

                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: ' 0.75rem' }}>
                      {formik.touched.files && (formik.errors.files || imageError)}
                    </Typography>
                  </div>
                  {images?.map((file, index) => (
                    <div
                      style={{
                        position: 'relative',
                        width: '100px',
                      }}>
                      <div
                        style={{
                          width: '100px',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '10px',
                        }}>
                         <CardMedia
                      component="img"
                      height="70px"
                      value={file.url}
                      image={(file.id && !file.url.endsWith(".pdf"))  ?  `${process.env.REACT_APP_BASE_URL}/${file.url}` : pdfFile}
                      alt="Selected Image"
                      // onClick={()=>handleCardClick(imageUrl)}
                      sx={{ borderRadius: '10px', boxShadow: 2, marginTop: '0px',objectFit:'fill', cursor:'pointer' }}
                    />
                      </div>
                      {meetingId ? (
                        <Link
                          href={`${process.env.REACT_APP_BASE_URL}/${file.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          style={{ display: 'block', marginBottom: '10px' }}>
                          <Button variant="outlined" size="small" sx={{ mt: '5px', width: '100px', fontWeight: '300' }}>
                            View File
                          </Button>
                        </Link>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                  {editimages?.map((file, index) => (
                    <div
                      style={{
                        position: 'relative',
                        width: '100px',
                      }}>
                      <div
                        style={{
                          width: '100px',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '10px',
                        }}>
                        <CardMedia
                      component="img"
                      height="70px"
                      value={file.url}
                      image={(file.type==="image" || (file.id && !file.url.endsWith(".pdf")))  ?  file.url : pdfFile}
                      alt="Selected Image"
                      // onClick={()=>handleCardClick(imageUrl)}
                      sx={{ borderRadius: '10px', boxShadow: 2, marginTop: '0px',objectFit:'fill', cursor:'pointer' }}
                    />
                      </div>
                    </div>
                  ))}
                </Grid>
              ) : (
                <Grid item xs={6} sx={{ display: 'flex', flexDirection: 'row', gap: '30px', flexWrap: 'wrap' }}>
                  <div>
                    <TextField
                      fullWidth
                      id="files-input"
                      name="files"
                      type="file"
                      multiple
                      InputProps={{ multiple: true }}
                      style={{ display: 'none' }}
                      onChange={(e) => handleFileChange(e)}
                      error={formik.touched.files && Boolean(formik.errors.files)}
                      helperText={formik.touched.files && formik.errors.files}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      aria-describedby="file-upload-label"
                    />

                    <Button
                      onClick={() => document.getElementById('files-input').click()}
                      disabled={images?.length >= 2}
                      sx={{
                        fontWeight: 500,
                        width: 'max-content',
                        height: '50px',
                        padding: '10px',
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        },
                      }}>
                      {meetingId ? 'Re-upload files' : 'Multiple-Upload Files'}
                    </Button>

                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: ' 0.75rem' }}>
                      {formik.touched.files && (formik.errors.files || imageError)}
                    </Typography>
                  </div>

                  {images?.map((file, index) => (
                    <div
                      style={{
                        position: 'relative',
                        width: '100px',
                      }}>
                      <div
                        style={{
                          width: '100px',
                          height: '70px',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          backgroundColor: '#f0f0f0',
                          borderRadius: '10px',
                        }}>
                        {/* <DescriptionIcon style={{ fontSize: 36 }} /> */}
                        <CardMedia
                      component="img"
                      height="70px"
                      value={file.url}
                      image={file.type==="image"  ?  file.url : pdfFile}
                      alt="Selected Image"
                      // onClick={()=>handleCardClick(imageUrl)}
                      sx={{ borderRadius: '10px', boxShadow: 2, marginTop: '0px',objectFit:'fill', cursor:'pointer' }}
                    />
                      </div>
                      {meetingId ? (
                        <Link
                          href={`${process.env.REACT_APP_BASE_URL}/${file.url}`}
                          target="_blank"
                          rel="noopener noreferrer"
                          underline="none"
                          style={{ display: 'block', marginBottom: '10px' }}>
                          <Button variant="outlined" size="small" sx={{ mt: '5px', width: '100px', fontWeight: '300' }}>
                            View File
                          </Button>
                        </Link>
                      ) : (
                        ''
                      )}

                      {!meetingId ? (
                        <IconButton
                          style={{
                            position: 'absolute',
                            top: '5px',
                            right: '5px',
                            background: 'red',
                            width: '10px',
                            height: '10px',
                          }}
                          onClick={() => handleSingleRemove(index)}>
                          <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                        </IconButton>
                      ) : (
                        ''
                      )}
                    </div>
                  ))}
                </Grid>
              )}
              {/* treeCuttingApplications */}
              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  options={applicationData || []}
                  getOptionLabel={(option) => `${option?.application_number} - ${option?.name} - ${option?.mobile}`}
                  value={selectedOptions2}
                  onChange={(event, newValue) => {
                    setSelectedOptions2(newValue);
                    const selectedIds = newValue.map((option) => option?.id);
                    setselectedApplicationeOptions(selectedIds);
                    formik.setFieldValue('treeCuttingApplications', selectedIds);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      name="treeCuttingApplications"
                      label="Add Tree Cutting Applications*"
                      placeholder="Add Tree Cutting Applications"
                      error={touched.treeCuttingApplications && Boolean(errors.treeCuttingApplications)}
                      helperText={touched.treeCuttingApplications && errors.treeCuttingApplications}
                    />
                  )}
                  renderOption={(props, option) => (
                    <li {...props}>
                      <StatusChip status={option.application_number} />- {option.name} - {option.mobile}
                    </li>
                  )}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        {...getTagProps({ index })}
                        label={
                          option.have_heritage_trees === 1 && option.have_100_plus_trees === 1 ? (
                            <span>
                              {option.application_number} - {option.name} - {option.mobile} -{' '}
                              <span style={{ color: 'red' }}> 100 Plus, Heritage</span>
                            </span>
                          ) : option.have_heritage_trees === 1 ? (
                            <span>
                              {option.application_number} - {option.name} - {option.mobile} -{' '}
                              <span style={{ color: 'red' }}>Heritage</span>
                            </span>
                          ) : option.have_100_plus_trees === 1 ? (
                            <span>
                              {option.application_number} - {option.name} - {option.mobile} -{' '}
                              <span style={{ color: 'red' }}> 100 Plus</span>
                            </span>
                          ) : (
                            `${option.application_number} - ${option.name} - ${option.mobile}`
                          )
                        }
                      />
                    ))
                  }
                  filterSelectedOptions

                  filterOptions={(options, { inputValue }) => {
                    const filteredOptions = options.filter((option) => 
                      option.application_number.includes(inputValue)
                    );
                
                    // Exclude already selected options
                    return filteredOptions.filter((option) => 
                      !selectedOptions2.some((selected) => selected.id === option.id)
                    );
                  }}
                />
              </Grid>

              {/* additional_notes */}
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  multiline
                  rows={3}
                  id="additional_notes"
                  name="additional_notes"
                  label="Additional Notes"
                  variant="outlined"
                  value={values.additional_notes}
                  onChange={formik.handleChange}
                  error={touched.additional_notes && Boolean(errors.additional_notes)}
                  helperText={touched.additional_notes && errors.additional_notes}
                />
              </Grid>
            </Grid>
            <Button
              variant="contained"
              style={{ display: 'flex', fontSize: 15, marginTop: 20, alignSelf: 'end', marginLeft: '80%' }}
              type="submit">
              {meetingId ? 'Update Meeting' : 'Create Meeting'}
            </Button>
          </form>
        )}
      </Card>)
}
</div>
  );
}
