import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import {
  Button,
  Card,
  Container,
  Stack,
  Typography,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  CircularProgress,
  Pagination,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  DialogActions,
  IconButton,
} from '@mui/material';
import { Close as CloseIcon } from '@mui/icons-material';

import { useFormik } from 'formik';

import * as Yup from 'yup';
import NewsPaperMoreAction from '../components/NewsPaperMoreAction';

import Page from '../components/Page';
import UserListHead from '../sections/@dashboard/user/UserListHead';
import UseMoreMenuAction from '../components/UseMoreMenuAction';
import TreeCuttingApplicationToolbar from '../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import {
  AddNewsPaper,
  DeleteNewsPaper,
  GetGetNewsPaperById,
  GetNewsPapers,
  SearchNewsPaper,
  UpdateNewsPaper,
} from '../actions/NewsPaperAction';

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'newsPaper', label: 'Newspaper', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

const NewsPaper = () => {
  const dispatch = useDispatch();
  const { newsPapers, newsPaperLoader, pageInfo, addNewsPapaerLog, singleNewsPaper } = useSelector((state) => ({
    newsPapers: state.NewsPaperReducer.newsPapers,
    newsPaperLoader: state.NewsPaperReducer.newsPaperLoader,
    singleNewsPaper: state.NewsPaperReducer.singleNewsPaper,
    pageInfo: state.NewsPaperReducer.pageInfo,
  }));
  console.log('singleNewsPaper', newsPapers);

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [open, setOpen] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [newsPaperId, setNewsPaperId] = useState();
  const [deleteId, setDeleteId] = useState();
  const [topModalOpen, setTopModalOpen] = React.useState(false);

  const [singleData, setSingleData] = useState();
  console.log('singleData', singleData);

  const validationSchema = Yup.object({
    newsPaperName: Yup.string()
      .required('Newspaper Name is required')
      .matches(/^[A-Za-z\s]+$/, 'Only alphabetic characters are allowed') // Restricts input to alphabetic characters and spaces
      .min(2, 'Too Short!')
      .max(50, 'Too Long!'),
  });
  

  const formik = useFormik({
    initialValues: {
      newsPaperName: '',
    },
    validationSchema,
    onSubmit: (values) => {
      console.log('Newspaper Name:', values.newsPaperName);
      const info = {
        newspaper: values.newsPaperName,
      };
      if (!newsPaperId) {
        dispatch(AddNewsPaper(info)).then((response) => {
          if (response.status === 201) {
            dispatch(GetNewsPapers(page, rowsPerPage));
          }
        });
      } else {
        dispatch(UpdateNewsPaper(info, newsPaperId)).then((response) => {
          if (response.status === 200) {
            dispatch(GetNewsPapers(page, rowsPerPage));
            setNewsPaperId()
          }
        });
      }

      handleClose();
      formik.resetForm(); 

    },
  });
  const { errors, touched, values, handleSubmit, getFieldProps } = formik;
  useEffect(() => {
    dispatch(GetNewsPapers(page, rowsPerPage));
  }, []);

  const handleEdit = (id) => {
    setNewsPaperId(id);

    if (id) {
      dispatch(GetGetNewsPaperById(id)).then((response) => {
        console.log('getbyid', response.data.data);
        if (response.status === 200) {
          setSingleData(response.data.data);
          // setfieldValue('newsPaperName', singleData.newspaper);
        }
      });
    }
    setOpen(true);
  };
  const handleDelete = (id) => {
    if (id) {
      dispatch(DeleteNewsPaper(id))
      .then((response) => {
      if (response.status === 200) {
      dispatch(GetNewsPapers(page, rowsPerPage));
      }
      });
    }
  };

  useEffect(() => {
    if (singleData) {
      formik.setFieldValue('newsPaperName', singleData?.newspaper);
    }
  }, [singleData]);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;

    clearTimeout(timer);
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  useEffect(() => {
    dispatch(SearchNewsPaper(1, rowsPerPage, searchValue));
  }, [searchValue]);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    formik.setFieldValue('newsPaperName','')
    formik.setFieldError('newsPaperName','')
    formik.setFieldTouched('newsPaperName',false)
    setOpen(false);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    if (searchValue) {
      dispatch(SearchNewsPaper(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetNewsPapers(newPage, rowsPerPage));
    }
  };

  const handleClearSearch = () => {
    setSearchValue("");
  }

  return (
    <Page title="Tree Cutting Application">
      <Container>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Newspapers
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all Newspapers
            </Typography>
          </Typography>
          <Button variant="contained" onClick={handleClickOpen}>
            Add Newspaper
          </Button>
        </Stack>

        <Card>
          <TreeCuttingApplicationToolbar
            onFilterName={filterByName}
            handleClearSearch = {handleClearSearch}
            showExport={false}
            // statusId={statusId}
            // handleStatusChange={handleStatusChange}
            // exportReports={exportReports}
            // handleexportReports={exportReports}
          />
          <TableContainer sx={{ minWidth: 700, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} />
              {newsPaperLoader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={3} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      <div style={{ display: 'flex', justifyContent: 'center' }}>
                        <CircularProgress />
                      </div>
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {newsPapers?.length > 0 ? (
                    newsPapers.map((value, index, info) => (
                      <TableRow hover key={value.id}>
                        <TableCell align="left">
                          <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                        </TableCell>
                        <TableCell align="left">{value.newspaper}</TableCell>
                        <TableCell align="right">
                          <NewsPaperMoreAction
                            handleConfirmDelete={() => handleDelete(value?.id)}
                            handleEdit={() => handleEdit(value.id)}
                          />
                        </TableCell>
                      </TableRow>
                    ))
                  ) : (
                    <TableRow>
                      <TableCell colSpan={3}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found!</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo ? pageInfo.last_page : 0}
              page={pageInfo ? pageInfo.current_page : 1}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>

        {/* Popup Dialog */}
        {open?
        <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
          <DialogTitle>
            {newsPaperId ? 'Update Newspaper' : 'Add Newspaper'}
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}>
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <form onSubmit={handleSubmit}>
            <DialogContent>
              <TextField
                name="newsPaperName"
                label="Newspaper Name"
                fullWidth
                variant="outlined"
                {...getFieldProps('newsPaperName')}
                error={touched.newsPaperName && Boolean(errors.newsPaperName)}
                helperText={touched.newsPaperName && errors.newsPaperName}
              />
            </DialogContent>
            <DialogActions>
              <Button type="submit" color="primary" variant="contained">
                {newsPaperId ? 'Update' : 'Save'}
              </Button>
            </DialogActions>
          </form>
        </Dialog>:null}
      </Container>
    </Page>
  );
};

export default NewsPaper;
