import React,{useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink, useNavigate ,useLocation} from 'react-router-dom';
import { Button, Grid, TextField, Typography, Stack, Card, IconButton, CardMedia} from '@mui/material';
import { AddProclamationObjection } from '../../actions/ProclamationActions';
import pdfFile from '../../Assets/pdf.png'

const AddObjection = () => {
  const navigate = useNavigate();

  const location = useLocation();

  console.log('locationObjecyioo', location.state);


  // const [uploadedPdf, setUploadedPdf] = useState('')
  // const [fileUploadError, setFileUploadError] = useState('');
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [imageError, setImageError] = useState(null);
  const [documentError, setDocumentError] = useState(null);
  const [imageUrl, setImageUrl] = useState('');
  const [documentType, setDocumentType] = useState('image');

  


  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml','application/pdf'];
  const allowFile = ['image/jpeg', 'image/jpg', 'image/png'];

  const handleDocumentChange = (event) => {
    const document = event.target.files[0];
    console.log("document",event.target.files[0])
    setSelectedDocument(document)
    const maxFileSize = 5 * 1024 * 1024; 

    const isValidFiles = allowedFileTypes.includes(document.type);
    const isValidSizes = document.size <= maxFileSize;

    if (!isValidFiles) {
      console.log("INSDIE IS VALID FILED")
      setDocumentError('File format not supported');
     
      // selectedDocument('')
      formik.setFieldError('document', 'File format not supported');
      // formik.setFieldValue('document', event.target.files[0])
      // formik.setFieldError('document', 'File format not supported');
    } else if (!isValidSizes) {
      formik.setFieldError('document', 'File size exceeds 5 MB');
      setDocumentError('File size exceeds 5 MB');
    } else if (isValidFiles && isValidSizes) {
      setDocumentError(null);
      if(document.type === "application/pdf") {
        setDocumentType("pdf")
      } else {
        setDocumentType("image")
      }
   formik.setFieldValue('document', event.target.files[0]) ;
   const reader = new FileReader();
      reader.onloadend = () => {
        setImageUrl(reader.result);
      };
      reader.readAsDataURL(event.target.files[0]);
    }
  };

  const handleRemoveDocument = () => {
    setSelectedDocument(null);
    formik.setFieldValue('document', null);
    formik.setFieldError('document', '');
    setDocumentError("")
  };
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      recivedfrom: '',
      objection: '',
      Date: '',
      document: ''
    },
    validationSchema: Yup.object({
      recivedfrom: Yup.string().required('Received from is required'),
      objection: Yup.string().required('Objection is required'),
      Date: Yup.date().required('Date is required'),
      document: Yup.mixed().required('Document is required')
    }),
    onSubmit: (values) => {
      console.log('Form Values:', values);

      const formData = new FormData();
      formData.append('objection_received',  values.objection)
      formData.append('objection_by_user',  values.recivedfrom)
      formData.append('objection_date',  values.Date)
      formData.append('document', values.document)
      
      // formData.append('objection_received',  values.document)
      // const info = {
      //   objection_received: values.objection,
      //   objection_by_user: values.recivedfrom,
      //   objection_date: values.Date,
      //   document: values.document

      // };
     console.log("Anjali98", dispatch(AddProclamationObjection(formData, location.state)).then((response)=>{
        if(response.status === 201){
          navigate("/dashboard/tree-cutting-application")
        }
      })
    )
    },
  });

  const handleCardClick = (fileUrl) => {
    if(documentType==="pdf") {
      const newTab = window.open();
        newTab.document.write(`
          <iframe 
            src="${fileUrl}" 
            width="100%" 
            height="100%" 
            style="border:none;">
          </iframe>
        `);
    }
    else {
    const newTab = window.open();
        newTab.document.write(`<img src="${fileUrl}" alt="Uploaded Image" width="100%" 
            height="100%"  />`);
    }
  }

  console.log("FORMIK",formik);

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {'Add Objection'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={2} columnSpacing={2}>
            {/* Received From */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="recivedfrom"
                name="recivedfrom"
                label="Received From (Name) *"
                variant="outlined"
                value={formik.values.recivedfrom}
                onChange={formik.handleChange}
                error={formik.touched.recivedfrom && Boolean(formik.errors.recivedfrom)}
                helperText={formik.touched.recivedfrom && formik.errors.recivedfrom}
              />
            </Grid>

            {/* Date */}
            <Grid item xs={6}>
              <TextField
                id="Date"
                name="Date"
                type="date"
                label="Date*"
                fullWidth
                error={Boolean(formik.touched.Date && formik.errors.Date)}
                helperText={formik.touched.Date && formik.errors.Date}
                value={formik.values.Date}
                onChange={formik.handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: new Date().toISOString().split('T')[0],
                }}
              />
            </Grid>

            {/* Objection */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                id="objection"
                name="objection"
                label="Objection*"
                variant="outlined"
                multiline
                rows={3}
                value={formik.values.objection}
                onChange={formik.handleChange}
                error={formik.touched.objection && Boolean(formik.errors.objection)}
                helperText={formik.touched.objection && formik.errors.objection}
              />
            </Grid>

            <Grid item xs={6}>
                {!selectedDocument ? (
                  <>
                    <input
                      // accept=".pdf,image"
                      style={{ display: 'none' }}
                      id="document-upload"
                      type="file"
                      onChange={handleDocumentChange}
                      name="files"

                    />
                    <Button
                      onClick={() => document.getElementById('document-upload').click()}
                      sx={{
                        fontWeight: 500,
                        width: '50%',
                        height: '50px',
                        padding: '10px',
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
                        '&:hover': {
                          boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                        },
                      }}>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        <DescriptionIcon />
                        <span>Upload Document</span>
                      </Stack>
                    </Button>
                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                      {formik.touched.document && (documentError || formik.errors.document)}
                    </Typography>
                   
                  </>
                ) : (
                  <Grid item xs={6} sx={{position:'relative'}}>
                    {/* <Typography>Uploaded Document-</Typography> */}
                    {/* <div
                      style={{
                        width: '100px',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '10px',
                        position: 'relative',
                      }}> */}
                      {/* <DescriptionIcon style={{ fontSize: 36 }} /> */}
                      <div style={{position:'relative',width:'150px'}}> 
                      <CardMedia
                      component="img"
                      height="150px"
                      // value={imageValue}
                      image={documentType==="image" ?  imageUrl : pdfFile}
                      alt="Selected Image"
                      onClick={()=>handleCardClick(imageUrl)}
                      sx={{ borderRadius: '10px', boxShadow: 2, marginTop: '0px',objectFit:'fill',position:'relative',cursor:'pointer',width:'150px' }}
                    />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          background: 'red',
                          width: '10px',
                          height: '10px',
                        }}
                        onClick={() => handleRemoveDocument()}>
                        <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                      </IconButton>
                      </div>
                    {/* </div> */}
                    <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: '0.75rem' }}>
                      {formik.touched.document && (documentError || formik.errors.document)}
                    </Typography>
                  </Grid>
                )}
              </Grid>
          </Grid>
          <Grid container justifyContent="flex-end" alignItems="flex-end" spacing={2}>
            <Button
              size="small"
              variant="contained"
              style={{
                display: 'flex',
                fontSize: 13,
                marginTop: 20,

                padding: '10px',
              }}
              type="submit">
              {'Create Objection'}
            </Button>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default AddObjection;
