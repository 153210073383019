import { filter, includes } from 'lodash';
import React, { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { Link as RouterLink, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TextField,
  Grid,
  Box,
  Pagination,
  CircularProgress,
  Backdrop,
  Drawer,
  MenuItem
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import Page from '../components/Page';
import Label from '../components/Label';
import Scrollbar from '../components/Scrollbar';
import Iconify from '../components/Iconify';
// eslint-disable-next-line import/named
import { UserListHead, UserListToolbar, UserFormListMenu } from '../sections/@dashboard/user';
import USERLIST from '../_mock/user';
import NewUserDialog from '../components/DialogBox/NewUserDialog';
import UserTableData from '../components/JsonFiles/UserTableData.json';

import { DeleteUsers, GetFilteredUsers, GetUsers, SearchUsers, UnlinkDevice } from '../actions/UserAction';
import WarningMessageDialog from '../components/DialogBox/WarningMessageDialog';
import UserActivateConfirmationDialog from '../components/DialogBox/UserActivateConfirmationDialog';
import StatusButton from '../components/statusbutton/StatusButton';
import warningSound from '../Assets/warning_sound.mp3';
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'name', label: 'Name', alignRight: false },
  { id: 'email', label: 'Email', alignRight: false },
  { id: 'mobile', label: 'Mobile', alignRight: false },
  { id: 'role', label: 'Role', alignRight: false },
  { id: 'username', label: 'Username', alignRight: false },
  { id: 'status', label: 'Status', alignRight: false },
  { id: 'activeTeam', label: 'Active Team', alignRight: false },
  { id: 'activeCouncil', label: 'Active Council', alignRight: false },
  { id: 'activeZone', label: 'Active Zone', alignRight: false },
  { id: 'activeWard', label: 'Active Ward', alignRight: false },
  { id: 'managers', label: 'Assigned Manager', alignRight: false },
  { id: 'action', label: 'Action', alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(array, (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1);
  }
  return stabilizedThis.map((el) => el[0]);
}

export default function User() {
  const dispatch = useDispatch();

  const [page, setPage] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(10);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('Name');
  const [filterName, setFilterName] = useState('');
  const [open, setOpen] = useState(false);
  const [close, setClose] = useState();
  const [dialogData, setDialogData] = useState(null);
  const [search, setSearch] = useState(false);
  const [searchValue, setSearchValue] = useState('');
  const [pageCountError, setPageCountError] = useState(false);
  const [topModalOpen, setTopModalOpen] = useState(false);
  const [activateModalOpen, setActivateModalOpen] = useState(false);
  const [reqObj, setReqObj] = useState(null);
  const [requireActiveObj, setReqActiveObj] = useState(null);
  const [showTableList, setShowTableList] = useState(false);
  const [roleId, setRoleId] = useState();
    const [filterBy, setFilterBy] = useState('role');

  const [showClearFilter, setShowClearFilter] = useState(false);
  const navigate = useNavigate();

  const userPermissions = [];
  const message =
    'Unlinking device will expired the current session of the user and might lose the offline data. Please synch all the Offline data before proceeding.';

  const { state } = useLocation();
  // console.log("topModalOpen", topModalOpen);
  // console.log("STATE PAGE ",state);
  // console.log("Current Page", page)
  const [tablehead, setTableHead] = useState(TABLE_HEAD);
    const [newState, setNewState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });

    const filterOptions = [
      { label: 'By Role', value: 'role' },
    ];

    const roleOption = [
      { id : '15' , name : 'Citizen'},
      { id : '13' , name : 'Site Inspector'},
      { id : '14' , name : 'Meeting Attendant'}
    ]

    const useStyles = makeStyles({
        success: {
          backgroundColor: '#DDFAD1',
          color: '#507C59',
          border: '1px solid #507C59',
          fontSize: '12px',
          borderRadius: '5px',
          padding: '4px 10px',
          fontWeight: 600,
        },
        pending: {
          backgroundColor: '#efcbbd',
          color: '#CE5623',
          border: '1px solid #CE5623',
          fontSize: '12px',
          borderRadius: '5px',
          padding: '4px 10px',
          fontWeight: 600,
          pointerEvents: 'none',
        },
        icon: {
          fill: '#214C50',
        },
      });

    const classes = useStyles();

  const { users, pageInfo, deleteUsersLog, loggedUser } = useSelector((state) => ({
    users: state.users.users,
    pageInfo: state.users.pageInfo,
    deleteUsersLog: state.users.deleteUsersLog,
    loggedUser: state.auth.loggedUser,
  }));

  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  const userRole = loggedUser?.roles[0].slug;

  useEffect(() => {
    if (userRole === 'council') {
      const filterTable = TABLE_HEAD.filter(
        (item) => !['activeTeam', 'activeCouncil', 'activeZone', 'managers', 'activeWard'].includes(item.id)
      );
      setTableHead(filterTable);
    }
  }, []);

  useEffect(() => {
    if (state) {
      // console.log("INSIDE STATE");
      setPage(state.page);
    }
  }, []);

  useEffect(() => {
    if (state) {
      setPage(state.page);
      dispatch(GetUsers(state.page, rowsPerPage));
    } else {
      dispatch(GetUsers(page, rowsPerPage));
    }
  }, [deleteUsersLog]);

  // useEffect(() => {
  //   if (pageInfo) {
  //     setCount(pageInfo?.total);
  //   }
  // }, [pageInfo]);
  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
      return;
    }
    setShowTableList(true);
  }, [pageInfo]);

  const handleEdit = (data) => {
    setDialogData(data);
    setOpen(!open);
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const handleChangePage = (event, newPage) => {
    // console.log('newPage', newPage);
    setPage(newPage);
    setShowTableList(false)

    if (search) {
      dispatch(SearchUsers(newPage, rowsPerPage, searchValue));
    } 
    else if(roleId) {
      dispatch(GetFilteredUsers(newPage, rowsPerPage,roleId))
    } else {
      dispatch(GetUsers(newPage, rowsPerPage));
    }
      
  };

  const handleDelete = (data) => {
    // console.log('activate_data', data);
    const activeObj = {
      data_id: data.id,
      data_status: data.status ? 0 : 1,
    };
    if (!data.status) {
      dispatch(DeleteUsers(activeObj));
    } else {
      handleactiveModalClose();
      setReqActiveObj(activeObj);
    }
    // dispatch(DeleteUsers(data.id, data.status ? 0 : 1));
  };

  let timer = null;
  const filterByName = (event) => {
    const value = event.currentTarget.value;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        dispatch(SearchUsers(1, rowsPerPage, value));
        setSearch(true);
        setPage(1);
        setSearchValue(value);
      } else {
        dispatch(GetUsers(1, rowsPerPage));
        setSearch(false);
        setPage(1);
        setSearchValue('');
      }
    }, 1000);
  };

  const handleUnlink = (userId) => {
    const obj = {
      user_id: userId,
    };
    handleTopModalClose();
    setReqObj(obj);
  };

  const audio = new Audio(warningSound);
  const handleTopModalClose = () => {
    setTopModalOpen(!topModalOpen);
  };

  const handleTopModalAnswer = (answer) => {
    if (answer) {
      audio.loop = false;
      dispatch(UnlinkDevice(reqObj));
    }
    audio.loop = false;
    setTopModalOpen(!topModalOpen);
  };

  const handleactiveModalClose = () => {
    setActivateModalOpen(!activateModalOpen);
  };

  const handleActivateModalAnswer = (answer) => {
    if (answer) {
      // console.log('aciveInactive')
      dispatch(DeleteUsers(requireActiveObj));
      audio.loop = false;
    }
    audio.loop = false;
    setActivateModalOpen(!activateModalOpen);
  };
  if (topModalOpen) {
    audio.loop = true;
    audio.play();
  }
  if (activateModalOpen) {
    audio.loop = true;
    audio.play();
  }

  const FilterSchema = Yup.object().shape({
    filter: Yup.string().required('Please select filter'),
    role: Yup.string().when("filter", {
      is: "role", // Condition: If filter equals "1"
      then: Yup.string().required("Role is required"),
      otherwise: Yup.string(),
    }),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter : 'role',
      role : ''
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      setPage(1);
      setShowClearFilter(true)
      dispatch(GetFilteredUsers(1, rowsPerPage,roleId));
      setNewState({ ...newState, right: false });
      
    },
  });

  const handleFilterChange = (event) => {
    setFilterBy(event.target.value);
  }

  const handleRoleChange = (event) => {
    setRoleId(event.target.value);
  };

  const handleClearFilter = () => {
    navigate(0); // Navigates to the current route, triggering a reload
    // setShowClearFilter(false)
  };

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="User">
      <Container>
        {/* <NewUserDialog
        isOpen={open}
        data={dialogData}
        // isClose={}
        /> */}
        <WarningMessageDialog
          isOpenConfirm={topModalOpen}
          message={message}
          handleClose={(answer) => handleTopModalAnswer(answer)}
        />
        <UserActivateConfirmationDialog
          isOpenConfirm={activateModalOpen}
          message={
            'Inactivating the user will expire their session. Please make sure they have synched all their offline data. Do you still want to Inactivate?'
          }
          handleClose={(answer) => handleActivateModalAnswer(answer)}
        />
        <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Users
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all users with its details
            </Typography>
          </Typography>
 <Box display="flex" flexDirection="row-reverse" gap={2}>
 {userRole==="council" ? (
            <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              // sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button>
          ) : null}
          {userPermissions.includes('create-user') ? (
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/new-user-Form"
              // style={{boxShadow: 'none'}}
              // startIcon={<Iconify icon="eva:plus-fill" />}
            >
              Add User
            </Button>
          ) : null}
          
          </Box>
        </Stack>

        <Stack direction="row"  mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '350px',
                  maxWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                  paddingTop:'2%'
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div style={{maxWidth:'100%'}} >
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}> 
                  <Stack direction="column" spacing={2} style={{width:'100%'}}>
                    <Grid item xs={12}>
                    <TextField
                    fullWidth
                      select
                      id="filter"
                      name="filter"
                      label="Select Filter Option"
                      displayEmpty
                      value={filterBy}
                      style={{ width: '100%', marginTop: 5,minWidth:'200px' }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e);
                        formik.handleChange(e);
                      }}
                      // InputLabelProps={{
                      //   shrink: true,
                      // }}
                      error={touched.filter && Boolean(errors.filter)}
                      helperText={touched.filter && errors.filter}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Filter*</em>
                      </MenuItem>
                      {filterOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  {filterBy==='role' &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="role"
                      name='role'
                      label="Role"
                      displayEmpty
                      value={roleId}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleRoleChange(e);
                        formik.handleChange(e);
                      }}
                      error={touched.role && Boolean(errors.role)}
                  helperText={touched.role && errors.role}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Role*</em>
                      </MenuItem>
                      {roleOption?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>
                  }
                </Stack>
                <Button
                  onClick={()=>formik.handleSubmit()}
                  variant="contained"
                  style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10 }}>
                  Apply
                </Button>
                {showClearFilter && 
                  <Button
                    onClick={()=>handleClearFilter()}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10,backgroundColor:'#bdbdbd' }}>
                    Clear Filter
                  </Button>
                  }
                </Grid>
                </div>
                </Drawer>
                </Box>
              </Stack>

        <Card>
          <UserListToolbar numSelected={0} placeHolder={'Search users...'} onFilterName={filterByName} />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table size="small" aria-label="a dense table">
                <UserListHead
                  headLabel={tablehead}
                  data={dialogData}
                  // handleClose = {handleNewUserClick}
                />
                <TableBody>
                  {showTableList &&
                    (users?.length > 0 ? (
                      users.map((option, index) => (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{(page - 1) * rowsPerPage + (index + 1)}</b>
                          </TableCell>
                          <TableCell
                            align="left"
                            >
                            {`${option.first_name} ${option.last_name}`}
                          </TableCell>
                          <TableCell align="left">{option.email}</TableCell>
                          <TableCell align="left">{option.mobile}</TableCell>
                          <TableCell align="left">{option.assigned_roles}</TableCell>
                          <TableCell align="left">{option.assigned_roles === "Citizen" ?  "-" : option.username}</TableCell>
                          <TableCell align="left">
                            <StatusButton status={option.status} />
                          </TableCell>
                          {userRole !== 'council' && (
                            <>
                              <TableCell align="left">
                                {option.active_team?.[0]?.name ? option.active_team?.[0]?.name : '-'}
                              </TableCell>
                              <TableCell align="left">
                                {option.active_team?.[0]?.active_council?.[0]?.name
                                  ? option.active_team?.[0]?.active_council?.[0]?.name
                                  : '-'}
                              </TableCell>
                              <TableCell align="left">
                                {option.active_team?.[0]?.active_zone?.[0]?.name
                                  ? option.active_team?.[0]?.active_zone?.[0]?.name
                                  : '-'}
                              </TableCell>
                              <TableCell align="left">
                                {option.active_team?.[0]?.active_ward?.[0]?.name
                                  ? option.active_team?.[0]?.active_ward?.[0]?.name
                                  : '-'}
                              </TableCell>
                              <TableCell align="left">-</TableCell>
                            </>
                          )}
                          <TableCell align="right">
                            <UserFormListMenu
                              page={page}
                              status={option.status}
                              roles={option.assigned_roles}
                              userId={option.id}
                              userPermissions={userPermissions}
                              handleEdit={() => handleEdit(option)}
                              handleDelete={() => handleDelete(option)}
                              handleUnlink={() => handleUnlink(option.id)}
                            />
                          </TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow>
                        <TableCell
                          colSpan={12}
                          align="center"
                          style={{ fontWeight: 500, marginTop: 15, marginBottom: 15 }}>
                          No data found
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>
          <Box>
            {users ? (
              <Pagination
                count={pageInfo.last_page}
                page={page}
                variant="outlined"
                shape="rounded"
                onChange={handleChangePage}
                sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
              />
            ) : null}
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
