import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Modal,
} from '@mui/material';
import { green, orange, red, gray } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import { GetSpecificTreeInfo } from '../../../actions/TreeOnMapAction';
import TreeDetailsWithReasonDialog from '../../../components/DialogBox/TreeCutting/TreeDetailsWithReasonDialog';
import MeetingListModal from '../../../components/DialogBox/meeting/MeetingListModal';
import SideInscpectorList from '../../../components/DialogBox/SiteInscpectorList';
import ApplicationStatusChip from '../../../components/ApplicationStatusChip';
import pdfImg from '../../../Assets/pdf.png';
import StatusChip from '../../../components/StatusChip';
import UpcomingReplantationHistory from '../../../components/DialogBox/TreeCutting/UpcomingReplantationHistory';


const ReplantationDetails = ({ applicationDetailsData, showLoader }) => {
  const dispatch = useDispatch();
  const { treeDetails, loggedUser } = useSelector((state) => ({
    treeDetails: state.treeLocation.treeDetails,
    loggedUser: state.auth.loggedUser,
  }));
  const [treeDetailsOpen, setDialogOpen] = useState(false);
  const [activeMarker, setActiveMarker] = useState(null);
  const [expandedIndex, setExpandedIndex] = useState(null);
  const [showHistoryModal, setShowHistoryModal] = useState(false);
  const [historyApplicationId, setHistoryApplicationId] = useState(false);

  const onMarkerClick = (marker) => {
    setDialogOpen(!treeDetailsOpen);
    if (marker === activeMarker) {
      return;
    }
    setActiveMarker(marker);
    dispatch(GetSpecificTreeInfo(marker));
  };

  console.log("SSSSapplicationDetailsData",applicationDetailsData)

  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [open, setOpen] = useState(false);
  const [selectedImageForModal, setSelectedImageForModal] = useState('');

  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      console.log('APPLICATIONID', application?.applicationId);
    }
  };

  const handleOpen = (imageUrl) => {
    setOpen(true);
    setSelectedImageForModal(imageUrl)
  }

  const handleShowHistoryModal = (applicationId) => {
    setShowHistoryModal(!showHistoryModal)
    if(applicationId) {
      setHistoryApplicationId(applicationId)
    }
  }

  const handleClose = () => setOpen(false);

  const handleAChange = (index) => (event, isExpanded) => {
    setExpandedIndex(isExpanded ? index : null);
  };
  return (
    <Box>
      {showLoader ? (
        <Card sx={{ margin: '15px', padding: '20px' }}>
          <Grid container>
            <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Card>
      ) : 
      <>
       {applicationDetailsData?.approved_cutting_trees?.length===0 ?
                          <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginTop : '100px',
                        textAlign: 'center',
                      }}
                    >
                      <Typography variant="h6" color="textSecondary">
                        No Record
                      </Typography>
                    </Box>
                    :

        <Box >
           { showHistoryModal ?
                    <UpcomingReplantationHistory
                    isOpen={showHistoryModal}
                    applicationId={historyApplicationId}
                    handleClose = {handleShowHistoryModal}
                     /> :null
                  }
          <Card sx={{ margin: '15px', padding: '20px' }}>

            <Grid container spacing={5}>
              <Grid item lg={12} sm={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                           
              <Button  size="small" variant="contained" sx={{ marginX: '10px' }} onClick={()=>handleShowHistoryModal(applicationDetailsData?.id)} >
                Replantation History
              </Button>
                              
                  </Grid>
              <Grid item lg={12}>
                <Grid container spacing={5}>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees Cut</Typography>
                    <Typography>{applicationDetailsData?.total_trees_permitted}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees to be Planted</Typography>
                    <Typography>{applicationDetailsData?.total_trees_to_be_planted}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Trees Planted</Typography>
                    <Typography>{applicationDetailsData?.total_trees_replanted}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Survived Trees</Typography>
                    <Typography>{applicationDetailsData?.survived_replanted_trees}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Died Trees</Typography>
                    <Typography>{applicationDetailsData?.died_replanted_trees}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Total Deposit Amount</Typography>
                    <Typography>₹ {applicationDetailsData?.deposit_amount} </Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Deducted Deposit Amount</Typography>
                    <Typography>₹ {applicationDetailsData?.deducted_deposit_amount}</Typography>
                  </Grid>
                  <Grid item lg={4} md={6} sm={12}>
                    <Typography sx={{ fontWeight: '700' }}>Next Due Date</Typography>
                    <Typography>{applicationDetailsData?.upcoming_replantation_updates ? applicationDetailsData?.upcoming_replantation_updates.due_date : "NA"}</Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Card>
          <Box sx={{margin:'15px'}} >
          {applicationDetailsData?.approved_cutting_trees?.map((info,index) => {
            return <Accordion
            key={index}
            sx={{ height: '100%',marginTop:'15px', borderRadius:'10px' }}
            // expanded={expandedIndex === index}
            onChange={handleAChange(index)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon sx={{ color: 'black' }} />}
              aria-controls="panel1-content"
            //   onClick={() => handleApplicationSelect(meetVal)}
              id="panel1-header">
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <Typography style={{ fontWeight: 700 }}>{info.site_inspection_report?.tree_name.name}({info?.replanted_trees.length}/{info.site_inspection_report?.age_by_girth})</Typography>
              </Box>
              <Divider style={{ marginY: '15px' }} />
            </AccordionSummary>
            <AccordionDetails>
            {/* <Divider sx={{ marginY: '15px' }} /> */}
            <Grid container spacing={2}>
            {info?.replanted_trees?.map((replantedTrees) => {
                return (
                  <Grid item lg={4} md={6} sm={12}>
                    <Card variant='outlined' sx={{ position: 'relative',padding: '5px', width: 'relative' }}>
                      {/* <CardActionArea> */}
                      <div style={{position:'absolute',right:'5px',top:'5px'}}>
                      <StatusChip  status={replantedTrees.status} />
                      </div>
                        <CardContent>
                        <Typography sx={{ fontWeight:'bold' }}> Tree Name</Typography>
                          <Typography>
                            {replantedTrees.tree_name.name}
                          </Typography>
                          <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Plantation Date</Typography>
                          <Typography>
                          {moment(replantedTrees.plantation_date).format('DD-MM-YYYY')}
                          </Typography>
                          <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Age (in years)</Typography>
                          <Typography>
                          {replantedTrees.age}
                          </Typography>
                          <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Height (in meters)</Typography>
                          <Typography>
                          {replantedTrees.approx_height}
                          </Typography>
                          {/* <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Status</Typography> */}
                          {/* <Typography>
                          {replantedTrees.status}
                          </Typography> */}
                          <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Last Updated At</Typography>
                          <Typography>
                          {replantedTrees.updated_at}
                          </Typography>
                          <Typography sx={{ marginTop: '5px',fontWeight:'bold' }}> Updated By</Typography>
                          <Typography>
                          {replantedTrees?.added_by?.full_name}
                          </Typography>
                        </CardContent>
                        <Box sx={{ position: 'relative' }}>
                        <CardMedia
                          component="img"
                          height="200"
                          sx={{ padding: '10px', borderRadius: '35px' }}
                          image={`${process.env.REACT_APP_BASE_URL}/${replantedTrees.tree_image}`}
                          alt="green iguana"
                        />
                         <IconButton
                    sx={{
                      color: 'black',
                      backgroundColor: 'white',
                      borderRadius: '50%',
                      boxShadow: '0 0 5px rgba(0,0,0,0.2)',

                      width: '25px',
                      height: '25px',
                      position: 'absolute',
                      top: 15,
                      right: 20,
                    }}
                    onClick={()=>handleOpen(replantedTrees.tree_image)}>
                    <VisibilityIcon fontSize="small" />
                  </IconButton>
                  </Box>
                      {/* </CardActionArea> */}
                    </Card>
                  </Grid>
                );
              })}
              </Grid>
            </AccordionDetails>
            </Accordion>
          })}
          </Box>
        </Box>
        }
        </>
      }
      {treeDetailsOpen ? <TreeDetailsWithReasonDialog isOpen={treeDetailsOpen} data={treeDetails} /> : null}
      <Modal
                  open={open}
                  onClose={handleClose}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}>
                  <Box
                    sx={{
                      position: 'relative',
                    }}>
                    <IconButton
                      onClick={handleClose}
                      sx={{
                        position: 'absolute',
                        top: '10px',
                        right: '10px',
                        color: 'black',
                      }}>
                      <CloseIcon />
                    </IconButton>

                    <img
                      src={`${process.env.REACT_APP_BASE_URL}/${selectedImageForModal}`}
                      alt="img"
                      height="600px"
                      width="600px"
                      style={{ borderRadius: '15px', objectFit: 'cover' }}
                    />
                  </Box>
                </Modal>
    </Box>
  );
};

export default ReplantationDetails;
