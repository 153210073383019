import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function UpcomingReplanationMenu(props) {
  // console.log('useMoreMenu', props,props.data?.newspaper_proclamations[0]?.fees_status);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = () => {
    console.log('edit calledin menu');
    props.handleEdit();
    setIsOpen(false);
  };

  const handleAddSiteInscpector = () => {
    console.log('handleAddSiteInscpector');
    setIsOpen(false);
    props.handleAddSiteInscpector();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: '#000' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 300, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
          
          {props.showReassign ?
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={() => handleAddSiteInscpector()}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>

            <ListItemText primary="Reassign Inspector" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem> :
          <MenuItem
          disabled = {props.disabled}
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={() => handleAddSiteInscpector()}>
          <ListItemIcon style={{ color: '#214c50' }}>
            <Iconify icon="gg:add" width={24} height={24} />
          </ListItemIcon>

          <ListItemText primary="Assign Inspector" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      }
       <MenuItem
          
          component={RouterLink}
          to="#"
          sx={{ color: 'text.secondary' }}
          onClick={() => props.handleHistoryModal()}>
          <ListItemIcon style={{ color: '#214c50' }}>
            <Iconify icon="mdi:eye" width={24} height={24} />
          </ListItemIcon>

          <ListItemText primary="View History" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}


