import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { element } from 'prop-types';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Taluka from './pages/Master/Taluka';
import District from './pages/Master/District';
import StateListTable from './pages/Master/StateListTable';
import Products from './pages/Products';
import DashboardApp from './pages/DashboardApp';
import CreateDesignation from './pages/Master/CreateDesignation';
import CreateRole from './pages/Master/CreateRole';
import CreateCouncil from './pages/Master/CreateCouncil';
import TypeOfTree from './pages/Master/TypeOfTree';
import CreateNameOfTree from './pages/Master/CreateNameOfTree';
import TypeOfProperty from './pages/Master/TypeOfProperty';
import TypeOfHouse from './pages/Master/TypeOfHouse';
import Zone from './pages/Master/Zone';
import Ward from './pages/Master/Ward';
import BaseColor from './pages/TreeData/BaseColor';
import DeniedEntry from './pages/TreeData/DeniedEntry';
import BaseColorHistory from './pages/TreeData/BaseColorHistory';
import TreeConditions from './pages/Master/TreeConditions';
import TeamsList from './pages/Teams/TeamsList';
import AssignNewCouncilZoneWard from './pages/Teams/AssignNewCouncilZoneWard';
import AssignUser from './pages/Teams/AssignUser';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import TermsCondition from './pages/TermsCondition';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Register from './pages/Register';
import NewUserForm from './pages/NewUserForm';
import NewManagerForm from './pages/NewManagerForm';
import LocationType from './pages/Master/LocationType';
import TreeDensity from './pages/Master/TreeDensity';
import ViewUser from './pages/Users/ViewUser';
import ViewAssociateUser from './pages/ViewAssociateUsers';
import QcRemarks from './pages/Master/QcRemarks';
import ViewProperties from './pages/Master/ViewProperties';
import Census from './pages/TreeData/Census';
import TreeDisease from './pages/Master/TreeDisease';
import TreeCensusHistory from './pages/TreeData/TreeCensusHistory';
import NoTreeProperty from './pages/TreeData/NoTreeProperty';
import TreeOnMap from './pages/Map/TreeOnMap';
import UserLiveLocation from './pages/Map/UserLiveLocation';
import RootTravel from './pages/Map/RootTravelLocation';
// import Reports from './pages/TreeData/Reports';
import NewUI from './pages/NewUI';
import BaseColorPendingQC from './pages/TreeData/BaseColorPendingQC';
import TreeFamilies from './pages/Master/TreeFamilies';
import WorkingReports from './pages/Reports/WorkingReports';
import TreeReports from './pages/Reports/TreeReports';
import Reports from './pages/TreeData/Reports';
import AssociateWithZeroTreeYesterday from './pages/Dashboardsection/AssociateWithZeroTreeYesterday';
import YesterdayLoggedInAssociates from './pages/Dashboardsection/YesterdayLoggedInAssociates';
import CallLog from './pages/AssociateTracking/CallLog/CallLog';
import SelfieList from './pages/SelfieVerification/SelfieList';
import PendingSelfie from './pages/SelfieVerification/PendingSelfie';
import Manager from './pages/Manager';
import RequestManager from './pages/RequestManager';
import RequestTravelling from './pages/RequestTravelling';
import DataCZWChange from './pages/DataAnalystCZW';
import TreeDataChange from './pages/TreeDataChange';
import Training from './pages/Training/Training';
import WorkReportTreeHistory from './pages/Reports/WorkReportTreeHistory';
import WorkReportBaseColorTreeHistory from './pages/Reports/WorkReportBaseColorTreeHistory';
import TreeCuttingPermission from './pages/TreeCutting/TreeCuttingPermission';
import NewTreeCuttingPermission from './pages/TreeCutting/NewTreeCuttingPermission';
import MomTreeCutting from './pages/TreeCutting/MomTreeCutting';
import TreeCompensation from './pages/TreeCutting/TreeCompensation';
import TreeCuttingPermissionReason from './pages/Master/TreeCuttingPermissionReason';
import TreeCuttingAllApplication from './pages/TreeCutting/TreeCuttingAllApplication';
import CreateMeeting from './pages/Meetings/CreateMeeting';
import ViewMeetings from './pages/Meetings/ViewMeetings';
import ViewDetailsTab from './sections/@dashboard/tree-cutting/ViewDetailsTab';
import ViewDetailsMeeting from './pages/Meetings/ViewDetailsMeeting';
import NewsPaper from './pages/NewsPaper';
import CreateNewsDialog from './components/DialogBox/CreateNewsDialog';
import UploadProclamation from './pages/Proclamation/UploadProclamation';
import AddObjection from './pages/Proclamation/AddObjection';
import AddRecipt from './pages/Proclamation/AddRecipt';
import PermissionLetter from './pages/PermissionLetter';
import UploadDepositeAmount from "./pages/UploadDepositeAmount/UploadDepositeAmount"
import UploadProcessingFeesReceipt from './pages/UploadProcessingFees/UploadProcessingFeesReceipt';
import DeletionPage from './pages/DeletionPage';
import UpcomingReplantation from './pages/TreeCutting/UpcomingReplantation';
import CuttedTrees from './pages/TreeCutting/CuttedTrees';
import TreeCutDashboard from './pages/TreeCutDashboard';
// import UpdateMeetingPage from './pages/Meetings/UpdateMeeting';

// dataCZWChange
// ----------------------------------------------------------------------

// export default function Router() {
//   return useRoutes([

//   ]);
// }

const routes = (isLogged) => [
  {
    path: '/dashboard',
    element: isLogged ? <DashboardLayout /> : <Navigate to="/" />,
    children: [
      { element: <Navigate to="/dashboard/home" />, index: true },
      { path: 'home', element: <DashboardApp /> },
      { path: '/dashboard/newspaper', element: <NewsPaper /> },
      { path: '/dashboard/cut-tree-home', element: <TreeCutDashboard /> },
      // { path: '/dashboard/newspaper', element: <CreateNewsDialog /> },

      { path: 'home/associateWithZeroTreeYesterday/:Id', element: <AssociateWithZeroTreeYesterday /> },
      { path: 'yesterdayLoggedInAssociates/:Id', element: <YesterdayLoggedInAssociates /> },
      { path: 'yesterdayLoggedInAssociates', element: <YesterdayLoggedInAssociates /> },
      { path: 'user', element: <User /> },
      { path: 'manager', element: <Manager /> },
      { path: 'requestmanager', element: <DataCZWChange /> },
      { path: 'wardChange', element: <RequestManager /> },
      { path: 'treeDataChange', element: <TreeDataChange /> },
      { path: 'requesttravelling', element: <RequestTravelling /> },
      { path: 'user/edit-user/:userId', element: <NewUserForm /> },
      { path: 'edit-manager/:userId', element: <NewManagerForm /> },
      { path: 'user/view-user/:userId', element: <ViewUser /> },
      { path: 'ViewAssociateUser/:userId', element: <ViewAssociateUser /> },
      { path: 'products', element: <Products /> },
      { path: 'blog', element: <Blog /> },
      { path: 'district', element: <District /> },
      { path: 'state', element: <StateListTable /> },
      { path: 'taluka', element: <Taluka /> },
      { path: 'designation', element: <CreateDesignation /> },
      { path: 'role', element: <CreateRole /> },
      { path: 'council', element: <CreateCouncil /> },
      { path: 'council/properties/:councilId/:councilName', element: <ViewProperties /> },
      { path: 'type-of-tree', element: <TypeOfTree /> },
      { path: 'families', element: <TreeFamilies /> },
      { path: 'name-of-tree', element: <CreateNameOfTree /> },
      { path: 'tree-condition', element: <TreeConditions /> },
      { path: 'type-of-property', element: <TypeOfProperty /> },
      { path: 'location-type', element: <LocationType /> },
      { path: 'tree-density', element: <TreeDensity /> },
      { path: 'qc-remarks', element: <QcRemarks /> },
      { path: 'cutting-permission-reason', element: <TreeCuttingPermissionReason /> },
      { path: 'type-of-house', element: <TypeOfHouse /> },
      { path: 'zone', element: <Zone /> },
      { path: 'ward', element: <Ward /> },
      { path: 'base-color', element: <BaseColor /> },
      { path: 'denied-entry', element: <DeniedEntry /> },
      { path: 'base-color/history/:baseColorId/:baseColorName', element: <BaseColorHistory /> },
      { path: 'teams', element: <TeamsList /> },
      { path: 'assignNewCouncilZoneWard/:teamId/:teamName', element: <AssignNewCouncilZoneWard /> },
      { path: 'assignUser/:teamId/:teamName/:teamType', element: <AssignUser /> },
      { path: 'new-user-form', element: <NewUserForm /> },
      { path: 'new-manager-form', element: <NewManagerForm /> },
      { path: 'census', element: <Census /> },
      { path: 'treeDisease', element: <TreeDisease /> },
      { path: 'treeCensus/history/:treeCensusId/:treeCensusName', element: <TreeCensusHistory /> },
      { path: 'no-tree-properties', element: <NoTreeProperty /> },
      { path: 'treeOnMap', element: <TreeOnMap /> },
      { path: 'censusQc', element: <NewUI /> },
      { path: 'baseColorPendingQC', element: <BaseColorPendingQC /> },
      { path: 'census-report', element: <Reports /> },
      { path: 'workingReports', element: <WorkingReports /> },
      { path: 'treeReports', element: <TreeReports /> },
      { path: 'callLogs', element: <CallLog /> },
      { path: 'all-selfie', element: <SelfieList /> },
      { path: 'pending-selfie', element: <PendingSelfie /> },
      { path: 'UserLiveLocation', element: <UserLiveLocation /> },
      { path: 'routeTravelled', element: <RootTravel /> },
      { path: 'training', element: <Training /> },
      {
        path: 'work-report/treeCensus/history/:censusUserId/:censusFromDate/:censusToDate/:treeStatus/:dateType',
        element: <WorkReportTreeHistory />,
      },
      {
        path: 'work-report/treeBaseColor/history/:censusUserId/:censusFromDate/:censusToDate/:treeStatus/:dateType',
        element: <WorkReportBaseColorTreeHistory />,
      },
      { path: 'tree-cutting', element: <TreeCuttingPermission /> },
      { path: 'create-tree-cutting-permission', element: <NewTreeCuttingPermission /> },
      { path: 'update-tree-cutting-permission/:applicationId', element: <NewTreeCuttingPermission /> },
      { path: 'tree-cutting/view-mom/:applicationId', element: <MomTreeCutting /> },
      {
        path: 'tree-cutting/compensation/:applicationId/:applicationNumber/:grantedTrees',
        element: <TreeCompensation />,
      },
      { path: 'tree-cutting-application', element: <TreeCuttingAllApplication /> },
      { path: 'upcoming-replantation', element: <UpcomingReplantation /> },
      { path: 'create-meeting', element: <CreateMeeting /> },
      { path: 'update-meeting/:meetingId', element: <CreateMeeting /> },
      { path: 'view-meetings', element: <ViewMeetings /> },

      { path: 'view-Details-application/:applicationId', element: <ViewDetailsTab /> },
      { path: 'view-details-meeting/:meetingId', element: <ViewDetailsMeeting /> },
      { path: 'proclamation/UploadProclamation', element: <UploadProclamation /> },
      { path: 'proclamation/AddObjection', element: <AddObjection /> },
      { path: 'proclamation/AddRecipt', element: <AddRecipt /> },
      { path: 'uploadDepositeAmount', element: <UploadDepositeAmount /> },
      { path: 'uploadProcessingFeesReceipt', element: <UploadProcessingFeesReceipt /> },

      { path: 'cut-trees', element: <CuttedTrees /> },
      { path: 'replanted-trees', element: <TreeCuttingAllApplication /> },


    ],
  },
  {
    path: '/',
    element: <LogoOnlyLayout />,
    children: [
      { path: '/', element: <Navigate to="/login" /> },
      { path: '/download-permission-letter/:applicationId', element: <PermissionLetter/> },
      { path: 'login', element: <Login /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '*', element: <Navigate to="/404" /> },
      { path: 'terms-&-conditions', element: <TermsCondition /> },
      { path: 'privacy-policy', element: <PrivacyPolicy /> },
      { path: 'deletion', element: <DeletionPage /> },
    ],
  },
  { path: '*', element: <Navigate to="/404" replace /> },
];

export default routes;
