import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Card, MenuItem, TextField } from "@mui/material";
import { makeStyles } from '@material-ui/core/styles';
import { Chart as ChartJS, BarElement, CategoryScale, LinearScale, Tooltip, Legend } from "chart.js";

ChartJS.register(BarElement, CategoryScale, LinearScale, Tooltip, Legend);

const DepositRefundSizedChart = (props) => {
  const [filterType, setFilterType] = useState('monthly');
  const [chartYLabel, setChartYLabel] = useState([]);
  const [chartXLabel, setChartXLabel] = useState([]);
  const [refundArray, setRefundArray] = useState([]);
  const [depositArray, setDepositArray] = useState([]);
  const [sizedArray, setSizedArray] = useState([]);
  
  useEffect(()=>{
    if(props?.data?.monthly_deposit_and_seized){
      
      const months = Object.keys(props.data.monthly_deposit_and_seized);
      const deposit = months.map(month => props.data.monthly_deposit_and_seized[month].total_deposit);
      const seized = months.map(month => props.data.monthly_deposit_and_seized[month].total_seized);
      const refund = months.map(month => props.data.monthly_deposit_and_seized[month].total_refunded);
      console.log("X LABEL",months)
      setChartXLabel(months)
      setDepositArray(deposit)
      setSizedArray(seized)
      setRefundArray(refund)
    }
  },[props])

  const handleFilterChange = (e) => {
    if(e.target.value==="yearly"){
      const months = Object.keys(props.data.yearly_deposit_and_seized);
      const deposit = months.map(month => props.data.yearly_deposit_and_seized[month].total_deposit);
      const seized = months.map(month => props.data.yearly_deposit_and_seized[month].total_seized);
      const refund = months.map(month => props.data.yearly_deposit_and_seized[month].total_refunded);
      setChartXLabel(months)
      setDepositArray(deposit)
      setSizedArray(seized)
      setRefundArray(refund)
    }
    else {
      const months = Object.keys(props.data.monthly_deposit_and_seized);
      const deposit = months.map(month => props.data.monthly_deposit_and_seized[month].total_deposit);
      const seized = months.map(month => props.data.monthly_deposit_and_seized[month].total_seized);
      const refund = months.map(month => props.data.monthly_deposit_and_seized[month].total_refunded);
      setChartXLabel(months)
      setDepositArray(deposit)
      setSizedArray(seized)
      setRefundArray(refund)
    }
    setFilterType(e.target.value)
  }
  
  const data = {
    labels: chartXLabel,
    datasets: [
      {
        label: "Deposit",
        data: depositArray,
        backgroundColor: "rgba(0, 0, 255, 0.7)", // Blue bars
        borderColor: "blue",
        borderWidth: 1,
      },
      {
        label: "Seized",
        data: sizedArray, // Sized data
        backgroundColor: "rgba(255, 0, 0, 0.7)", // Red bars
        borderColor: "red",
        borderWidth: 1,
      },
      {
        label: "Refund",
        data: refundArray, // Refund data
        backgroundColor: "rgba(0, 128, 0, 0.7)", // green bars
        borderColor: "green",
        borderWidth: 1,
      },
    
    ],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top", // Position of the legend
      },
      tooltip: {
        enabled: true,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: filterType === "monthly"?`Months (${(new Date().getFullYear())})`  : "Year" ,
        },
      },
      y: {
        title: {
          display: true,
          text: "Amount (in INR)", // Y-axis title
        },
        beginAtZero: true, // Ensures Y-axis starts at 0
      },
    },
  };

  const useStyles = makeStyles({
    common: {
      padding: '5px 5px 5px 5px',
      border: '2px solid #d1cfcf',
    },
    icon: {
      fill: '#214C50',
    }
  });

    const classes = useStyles();

  return (
    <Card className={classes.common}>
       <div style={{width:'20%',display:'flex',justifySelf:'flex-end',marginRight:'2%'}}>
      <TextField
                    fullWidth
                      select
                      id="revenueBy"
                      name="revenueBy"
                      displayEmpty
                      value={filterType}
                      style={{ width: '100%', marginTop: 10 }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e)
                      }}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem key="monthly" value="monthly">
                        <em>Monthly</em>
                      </MenuItem>
                      <MenuItem key="yearly" value="yearly">
                        <em>Yearly</em>
                      </MenuItem>
                     
                    </TextField>
      </div>
    <div style={{ width: "90%", margin: "auto", maxWidth: "800px" }}>
      <Bar data={data} options={options} />
    </div>
    </Card>
  );
};

export default DepositRefundSizedChart;
