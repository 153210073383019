import { useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
// component
import ReceiptLongOutlinedIcon from '@mui/icons-material/ReceiptLongOutlined';

import Iconify from './Iconify';

// ----------------------------------------------------------------------

export default function UseMoreMenuAction(props) {
  // console.log('useMoreMenu', props,props.data?.newspaper_proclamations[0]?.fees_status);
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const handleEdit = () => {
    console.log('edit calledin menu');
    props.handleEdit();
    setIsOpen(false);
  };

  const handleDelete = () => {
    setIsOpen(false);
    props.handleDelete();
  };
  const handleAddToMeeting = () => {
    setIsOpen(false);
    props.handleAddToMeeting();
  };
  const handleAddSiteInscpector = () => {
    console.log('handleAddSiteInscpector');
    setIsOpen(false);
    props.handleAddSiteInscpector();
  };

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} style={{ color: '#000' }} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 300, maxWidth: '100%' },
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}>
        {props.IsApplication && (props.statusId === 5 || props.statusId === 10 || props.statusId === 13) && (
          <MenuItem component={RouterLink} to="#" sx={{ color: 'text.secondary' }} onClick={() => handleAddToMeeting()}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Add to meeting" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {props.IsApplication && props.statusId === 1 && (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={() => handleAddSiteInscpector()}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>

            <ListItemText primary="Ready for inspection" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

{props.IsApplication && (props.statusId === 2 || props.statusId===3) && (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={() => handleAddSiteInscpector()}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>

            <ListItemText primary="Reassign Site Inspector" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {(props.status === 'Scheduled') && (
          <MenuItem
            component={RouterLink}
            to={`${props.editUrl}/${props.id}`}
            sx={{ color: 'text.secondary' }}
            onClick={() => handleEdit()}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="basil:edit-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Edit" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {!props.IsApplication && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/view-details-meeting/${props.id}`}
            sx={{ color: 'text.secondary' }}
            disabled={props.status === 'Cancelled'}>
            <ReceiptLongOutlinedIcon />


            <ListItemText primary="Details" primaryTypographyProps={{ variant: 'body2' }} sx={{marginLeft:"10px"}}/>
          </MenuItem>
        )}

        {!props.IsApplication && props.status !== 'Done' && (
          <MenuItem
            component={RouterLink}
            to="#"
            sx={{ color: 'text.secondary' }}
            onClick={() => handleDelete()}
            disabled={props.status === 'Cancelled'}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="mdi:close" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Cancel" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {props.IsApplication && (
          <MenuItem
            component={RouterLink}
            to={`/dashboard/view-Details-application/${props.id}`}
            sx={{ color: 'text.secondary' }}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="mdi:eye" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="View" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {/* add upload-procalamation */}
         {props.IsApplication && props.statusId === 7 && ( 
          <MenuItem
            component={RouterLink}
            to={'/dashboard/proclamation/UploadProclamation'}
            sx={{ color: 'text.secondary' }}
            state={props.data}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Update Proclamation" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
         )}
        {/* add objection */}
        {props.IsApplication && props.statusId === 8 && (
          <MenuItem
            component={RouterLink}
            to={'/dashboard/proclamation/AddObjection'}
            state={props.ProclamationId}
            sx={{ color: 'text.secondary' }}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Add Proclamation Objection" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* upload recipt */}
        {props.IsApplication && 
         (props.statusId === 8 || props.statusId === 9 || props.statusId === 13)
          && props.data?.newspaper_proclamations[0]?.fees_status === 'Unpaid' && (
          <MenuItem
            component={RouterLink}
            to={'/dashboard/proclamation/AddRecipt'}
            sx={{ color: 'text.secondary' }}
            state={{ data: props.data, ProclamationId: props.ProclamationId }}>
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Upload Proclamation Receipt" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}

        {props.IsApplication && props.statusId === 4 && (
          <MenuItem
            component={RouterLink}
            to={'/dashboard/uploadDepositeAmount'}
            sx={{ color: 'text.secondary' }}
            state={props.data}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Update Deposit Amount" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* upload processing fees */}
        {props.IsApplication && props.statusId === 11 && (
          <MenuItem
            component={RouterLink}
            to={'/dashboard/uploadProcessingFeesReceipt'}
            sx={{ color: 'text.secondary' }}
            state={{ data: props.data }}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Upload Processing Fees Receipt" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
        {/* download permission letter */}
        {props.IsApplication && props.statusId === 12 && (
          <MenuItem
            component={RouterLink}
            to={`/download-permission-letter/${props.id}`}
            target='blank'
            sx={{ color: 'text.secondary' }}
            // onClick={() => handleDelete()}
          >
            <ListItemIcon style={{ color: '#214c50' }}>
              <Iconify icon="gg:add" width={24} height={24} />
            </ListItemIcon>
            <ListItemText primary="Download Permission Letter" primaryTypographyProps={{ variant: 'body2' }} />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
