import JWTServer from '../api/withJWTServer';
import { SetNewAlert } from './AlertActions';
import { HandleExceptionWithSecureCatch } from './CombineCatch';
import {
  ADD_MEETING,
  GET_MEETINGS,
  GET_ATTENDEES,
  GET_TREE_CUTTING_APPLICATIONS,
  UPDATE_MEETING,
  CANCEL_MEETING,
  GET_MEETING_BY_ID,
  EXCEL_MEETINGS_REPORT,
  RESET_STATE,
  ADD_APPLICATION_TO_MEETING,
  GET_FUTURE_MEETINGS,
  DETAILS_MEETING_BY_ID,
  ADD_MEETING_REMARK,
} from './Types';

const GetMeetings = (page, limit,filterBy, filterId , filterStartDate, filterEndDate , filterInput,status) => async (dispatch) => {
  
  let url = `/api/v1/cutting-tree-application/meetings?page=${page}&limit=${limit}`;

  if (filterBy) {
    url = `${url}&filter_by=${filterBy}`;
  }

  if (filterId) {
    url = `${url}&filter_id=${filterId}`;
  }

  if (filterStartDate && filterEndDate) {
    url = `${url}&filter_start_date=${filterStartDate}&filter_end_date=${filterEndDate}`;
  }

  if (filterInput) {
    url = `${url}&filter_input=${filterInput}`;
  }

  if (status) {
    url = `${url}&status=${status}`;
  }

  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: GET_MEETINGS,
      payload: response.data,
    });
    return response; // Return the response to the component
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetMeetingById = (id) => async (dispatch) => {
  console.log('meetingId8899898987', id);
  try {
    const response = await JWTServer.get(`/api/v1/cutting-tree-application/meetings/${id}`);

    dispatch({
      type: GET_MEETING_BY_ID,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const SearchMeeting = (page, limit, searchValue) => async (dispatch) => {
  try {
    const response = await JWTServer.get(
      `/api/v1/cutting-tree-application/meetings?page=${page}&limit=${limit}&search=${searchValue}`
    );
    dispatch({
      type: GET_MEETINGS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetFutureMeetings = () => async (dispatch) => {
  try {
    const response = await JWTServer.get(`/api/v1/cutting-tree-application/meetings?status=Scheduled`);
    dispatch({
      type: GET_FUTURE_MEETINGS,
      payload: response.data,
    });
    console.log(response);
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const AddApplicationToMeeting = (params) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.post(`/api/v1/add-to-meeting`, params);
    dispatch({
      type: ADD_APPLICATION_TO_MEETING,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const AddMeeting = (params) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.post(`api/v1/cutting-tree-application/meeting`, params);
    dispatch({
      type: ADD_MEETING,
      payload: response.data,
    });

    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const UpdateMeeting = (params, id) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.post(`api/v1/cutting-tree-application/update-meeting/${id}`, params);
    dispatch({
      type: UPDATE_MEETING,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const detailMeeting = (id) => async (dispatch) => {
  try {
    const response = await JWTServer.get(`api/v1/cutting-tree-application/meetings/${id}`);
    dispatch({
      type: DETAILS_MEETING_BY_ID,
      payload: response.data,
    });
    // dispatch(
    //   SetNewAlert({
    //     msg: response.data.message,
    //     alertType: 'success',
    //   })
    // );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const CancelMeeting = (id) => async (dispatch) => {
  // console.log('valueinAction', params);
  try {
    const response = await JWTServer.patch(`api/cutting-tree-application/meeting/${id}/cancel`);
    dispatch({
      type: CANCEL_MEETING,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const GetExcelMeeting = (filterBy, filterId , filterStartDate, filterEndDate , filterInput, status) => async (dispatch) => {
  let url = `/api/v1/cutting-tree-application/meetings`;

  if (filterBy) {
    url = `${url}?filter_by=${filterBy}`;
  }

  if (status) {
    url = `${url}?status=${status}`;
  }

  if (filterId) {
    url = `${url}&filter_id=${filterId}`;
  }

  if (filterStartDate && filterEndDate) {
    url = `${url}&filter_start_date=${filterStartDate}&filter_end_date=${filterEndDate}`;
  }

  if (filterInput) {
    url = `${url}&filter_input=${filterInput}`;
  }

  
  try {
    const response = await JWTServer.get(`${url}`);
    dispatch({
      type: EXCEL_MEETINGS_REPORT,
      payload: response.data,
    });
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetAttendeesList = (params) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.get(`/api/users?status=1&role_id[]=14`);
    dispatch({
      type: GET_ATTENDEES,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

const GetTreeCuttingApplicationsList = (params) => async (dispatch) => {
  console.log('valueinAction', params);
  try {
    const response = await JWTServer.get(
      `api/v1/cutting-tree-applications?application_status[0]=10&application_status[1]=5&application_status[2]=13&for_meeting=1`
    );

    console.log('89797786', response);
    dispatch({
      type: GET_TREE_CUTTING_APPLICATIONS,
      payload: response.data,
    });
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};
const resetMeeting = (params) => async (dispatch) => {
  console.log('reset called', params);

  dispatch({
    type: RESET_STATE,
  });
};
const addMeetingRemark = (params, id) => async (dispatch) => {
  try {
    const response = await JWTServer.post(`api/add-meeting-remark`,params);
    dispatch({
      type: ADD_MEETING_REMARK,
      payload: response.data,
    });
    dispatch(
      SetNewAlert({
        msg: response.data.message,
        alertType: 'success',
      })
    );
    return response;
  } catch (e) {
    dispatch(HandleExceptionWithSecureCatch(e));
  }
};

export {
  resetMeeting,
  GetMeetings,
  SearchMeeting,
  AddMeeting,
  GetAttendeesList,
  GetTreeCuttingApplicationsList,
  UpdateMeeting,
  CancelMeeting,
  GetMeetingById,
  GetExcelMeeting,
  AddApplicationToMeeting,
  GetFutureMeetings,
  detailMeeting,
  addMeetingRemark,
};
