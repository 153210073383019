import React, { useEffect, useState } from "react";
import * as Yup from 'yup';
import Box from '@mui/material/Box';
import moment from 'moment';
import Stack from '@mui/material/Stack';

import { makeStyles } from "@material-ui/core/styles";
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import { Visibility } from '@mui/icons-material';
import CancelIcon from '@mui/icons-material/Cancel';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Typography from '@mui/material/Typography';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { Modal, Container, InputAdornment, TableContainer, TableBody, TableRow, TableCell, CircularProgress, Table } from '@mui/material';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { GetAbellMetaDetails, UpdateAbellMetaDetails } from "../../../actions/AbellAction";
import { UserListHead } from "../../../sections/@dashboard/user";
import { GetReplantationHistory } from "../../../actions/UpcomingReplantationAction";
// import Typography from 'src/theme/overrides/Typography';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};
const useStyles = makeStyles(() => ({
  noBorder: {
    border: "none",
  },
}));

const TABLE_HEAD = [
    { id: 'srno', label: '#', alignRight: false },
    { id: 'TotalExpectedTrees', label: 'Total Expected Trees', alignRight: false },
    { id: 'TotalUpdatedTrees', label: 'Total Updated Trees', alignRight: false },
    { id: 'TotalDiedTrees', label: 'Total Died Trees', alignRight: false },
    { id: 'AssignedTo', label: 'Assigned To', alignRight: false },
    { id: 'Duedate', label: 'Due Date', alignRight: false },
    
  ];

export default function UpcomingReplantationHistory(props) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214c50',
    },
  });
  const classes = useStyles();
  const dispatch = useDispatch();
  const { isOpen } = props;
  const [open, setOpen] = React.useState(false);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('md');
    const [loader, setLoader] = useState(false);

  const [metaList, setMetaList] = useState([
    { metaName: '', metaValue: '', errorName: '', errorValue: '' },
  ]);


  const userPermissions = [];


  const {
    abellMetaDetails,
    loggedUser,
    addAbellMetaDetailsLog,
    replantationHistory
  } = useSelector((state) => ({
    abellMetaDetails: state.abell.abellMetaDetails,
    loggedUser: state.auth.loggedUser,
    addAbellMetaDetailsLog : state.abell.addAbellMetaDetailsLog,
    replantationHistory: state.upcomingReplantation.replantationHistory,
  }));
  loggedUser.roles[0].permissions.map((item, index) => userPermissions.push(item.name));

  React.useEffect(() => {
    if (isOpen) {
        setLoader(true);
        dispatch(GetReplantationHistory(props?.applicationId)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
    }
  }, []);

  console.log("REPLANATION HISTORY",replantationHistory);

  const secondRun = React.useRef(true);
  React.useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    console.log("hii")
  }, [abellMetaDetails]);


  const forthRun = React.useRef(true);
  useEffect(() => {
    if (forthRun.current) {
      forthRun.current = false;
      return;
    }
    props.handleClose();
  }, [addAbellMetaDetailsLog]);

  const handleClose = () => {
    props.handleClose();
  };


  const reLast = /-\d+$/;

  return (
    <div>
      {/* <Button variant="outlined" onClick={handleClickOpen}>
        Open max-width dialog
      </Button> */}
      <Dialog
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        open={isOpen}
        sx={{minHeight:'400px'}}
      // onClose={handleClose}
      // onClose={handleClose}
      >
        <Box>
          <Stack justifyContent="space-between" direction="row"
            useFlexGap flexWrap="wrap">
            <BootstrapDialogTitle  onClose={handleClose}>Replantation History</BootstrapDialogTitle>
            
          </Stack>
        </Box>

        <Divider />
        <DialogContent>
            <div>

            <TableContainer sx={{ minWidth: 200, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                    {replantationHistory?.length > 0 ? (
                        replantationHistory?.map((value,index)=>{
                            return (
<TableRow hover>
                          <TableCell align="left">
                            {index+1}
                          </TableCell>
                          <TableCell align="left">
                            {value?.total_expected_trees}
                          </TableCell>
                          <TableCell align="left">
                          {value?.total_updated_trees}
                          </TableCell>
                          <TableCell align="left">
                          {value?.died_replanted_trees_count}
                          </TableCell>
                          <TableCell align="left">
                            {value?.assigned_to ? value?.assigned_to?.full_name : value?.total_updated_trees >0 ? "Self" : "-" }
                          </TableCell>
                          <TableCell align="left">
                            {value?.due_date}
                          </TableCell>
                    </TableRow>
                            )
                        })
                    ) :
                    (
                        <TableRow>
                      <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                    )
                    }
                </TableBody>
              )}
              </Table>
              </TableContainer>
            </div>
          
        </DialogContent>
        <Divider />
      </Dialog>
    </div>
  );
}
